/* eslint-disable no-plusplus */
/* eslint-disable max-len */

import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addActivityLog } from './salesAccountsActions';
import formatAmount from '../../utils/formatAmount';

export const GET_PAYMENTS_RECEIVED_LIST = 'GET_PAYMENTS_RECEIVED_LIST';
export const GET_PAYMENTS_RECEIVED = 'GET_PAYMENTS_RECEIVED';
export const GET_PAYMENT_RECEIVED = 'GET_PAYMENT_RECEIVED';
export const CLEAR_PAYMENT_RECEIVED = 'CLEAR_PAYMENT_RECEIVED';
export const ADD_PAYMENT_RECEIVED = 'ADD_PAYMENT_RECEIVED';
export const DELETE_PAYMENT_RECEIVED = 'DELETE_PAYMENT_RECEIVED';
export const EDIT_PAYMENT_RECEIVED = 'EDIT_PAYMENT_RECEIVED';
export const GET_PAYMENT_RECEIVED_DOCS = 'GET_PAYMENT_RECEIVED_DOCS';
export const ADD_PAYMENT_RECEIVED_DOCS = 'ADD_PAYMENT_RECEIVED_DOCS';
export const DELETE_PAYMENT_RECEIVED_DOC = 'DELETE_PAYMENT_RECEIVED_DOC';
export const GET_PAYMENT_RECEIVED_JOURNAL = 'GET_PAYMENT_RECEIVED_JOURNAL';
export const CLEAR_PAYMENT_RECEIVED_JOURNAL = 'CLEAR_PAYMENT_RECEIVED_JOURNAL';
export const PAYMENT_RECEIVED_LOADING = 'PAYMENT_RECEIVED_LOADING';
export const PAYMENT_RECEIVED_LOADED = 'PAYMENT_RECEIVED_LOADED';
export const REFUND_PAYAMENT_RECEIVED = 'REFUND_PAYAMENT_RECEIVED';
export const GET_PAYMENT_RECEIVED_REFUNDS = 'GET_PAYMENT_RECEIVED_REFUNDS';

// GET LATEST INVOICE PAYMENT NUMBER
export const getLatestInvoicePaymentNum = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/invoicePayment/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET PAYMENT RECEIVED
export const getPaymentReceived = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentsReceived/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PAYMENT_RECEIVED, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR PAYMENT RECEIVED
export const clearPaymentReceived = () => {
  return { type: CLEAR_PAYMENT_RECEIVED };
};

// GET PAYMENTS RECEIVED LIST
export const getPaymentsReceivedList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_RECEIVED_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/paymentsReceived`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENTS_RECEIVED_LIST, payload: res.data });
    dispatch({ type: PAYMENT_RECEIVED_LOADED });
  } catch (err) {
    dispatch({ type: PAYMENT_RECEIVED_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PAYMENTS RECEIVED
export const getPaymentsReceived = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentsReceived/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENTS_RECEIVED, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST PAYMENT RECEIVED
export const getLatestPaymentReceived = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentsReceived/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// DELETE PAYMENT RECEIVED
export const deletePaymentReceived = payment => async (dispatch, getState) => {
  const newActivityLog = {
    customer_id: payment.sales_account_id,
    activity_type: 'Payment Received',
    activity_title: 'Payment Deleted',
    module_num: payment.payment_num,
    amount: payment.total,
    description: `Invoice payment of amount ${
      payment.currency_symbol
    }${formatAmount(payment.total)} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/paymentsReceived/${payment.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'payment_received',
          payment_date: payment.payment_date,
        },
      }
    );
    dispatch(createMessage({ message: 'Payment Deleted' }));
    dispatch({ type: DELETE_PAYMENT_RECEIVED, payload: payment.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD PAYMENT RECEIVED
export const addPaymentReceived = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestInvoicePaymentNum());
    let lastNum = latestNum.latest_num ? latestNum.latest_num : 0;

    const isNoAmountApplied =
      values.unpaid_invoices.filter(invoice => invoice.amount_applied > 0)
        .length === 0;
    const invoicePayments = values.unpaid_invoices
      .filter(invoice => invoice.amount_applied > 0)
      .map(invoice => {
        if (invoice.invoice_num === 'Account Opening Balance') {
          return {
            transaction_num: ++lastNum,
            sales_account: invoice.id,
            amount_applied: invoice.amount_applied,
          };
        }
        return {
          transaction_num: ++lastNum,
          invoice_id: invoice.invoice ? invoice.invoice.id : invoice.id,
          amount_applied: invoice.amount_applied,
        };
      });

    const newPyamentReceived = {
      sales_account_id: values.sales_account_id,
      chart_of_account_id: values.chart_of_account_id,
      currency: values.currency,
      // payment_num: values.payment_num,
      payment_date: moment(values.payment_date).format('YYYY-MM-DD'),
      payment_mode: values.payment_mode,
      exchange_rate: round(values.exchange_rate, 6),
      reference_num: values.reference_num,
      notes: values.notes,
      bank_charges: values.bank_charges,
      total: round(values.amount_received, 2),
      unused_amount: isNoAmountApplied
        ? round(values.amount_received, 2)
        : values.amount_in_excess,
      invoice_payments: invoicePayments,
      type: 'payment_received',
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
    };

    if (values.parent) newPyamentReceived.parent = values.parent;

    const invoiceNums = values.unpaid_invoices
      .filter(invoice => invoice.amount_applied > 0)
      .reduce(
        (acc, val) =>
          `${acc}${val.invoice_num}${
            val.customer_type_suffix ? `-${val.customer_type_suffix}` : ''
          }${val.invoice_prefix ? `-${val.invoice_prefix}` : ''}, `,
        ''
      );

    const res = await axios.post(
      `${API_URL}/api/accounting/sales/paymentsReceived/`,
      newPyamentReceived,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: res.data.id,
      customer_id: values.sales_account_id,
      activity_type: 'Payment Received',
      activity_title: 'Payment Added',
      module_num: values.payment_num,
      amount: round(values.amount_received, 2),
      description: `Payment of amount ${values.currency_symbol}${formatAmount(
        values.amount_received
      )} received and applied for ${invoiceNums}`,
    };
    dispatch(createMessage({ message: 'Payment Added' }));
    dispatch({ type: ADD_PAYMENT_RECEIVED, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT PAYMENT RECEIVED
export const editPaymentReceived = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestInvoicePaymentNum());
    let lastNum = latestNum.latest_num ? latestNum.latest_num + 1 : 0;

    // Invoice Payments and Unpaid Invoices are merged which can be distinguished by the flag "isInvoicePayment"
    const isNoAmountApplied =
      values.invoices.filter(invoice => invoice.amount_applied > 0).length ===
      0;
    const invoicePayments = values.invoices
      .filter(invoice => invoice.amount_applied > 0)
      .map(item => {
        if (item.sales_account) {
          return {
            transaction_num: ++lastNum,
            sales_account: item.sales_account.id,
            amount_applied: item.amount_applied,
          };
        }
        if (
          item.invoice_num &&
          item.invoice_num === 'Account Opening Balance'
        ) {
          return {
            transaction_num: ++lastNum,
            sales_account: item.id,
            amount_applied: item.amount_applied,
          };
        }
        return {
          transaction_num: ++lastNum,
          invoice_id: item.isInvoicePayment ? item.invoice.id : item.id,
          amount_applied: item.amount_applied,
        };
      });

    const editedPyamentReceived = {
      sales_account_id: values.sales_account_id,
      chart_of_account_id: values.chart_of_account_id,
      currency: values.currency,
      payment_date: moment(values.payment_date).format('YYYY-MM-DD'),
      payment_mode: values.payment_mode,
      exchange_rate: round(values.exchange_rate, 6),
      reference_num: values.reference_num,
      notes: values.notes,
      bank_charges: values.bank_charges,
      total: round(values.amount_received, 2),
      unused_amount: isNoAmountApplied
        ? round(values.amount_received, 2)
        : values.amount_in_excess,
      invoice_payments: invoicePayments,
      type: 'payment_received',
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
    };

    const newActivityLog = {
      module_id: values.id,
      customer_id: values.sales_account_id,
      activity_type: 'Payment Received',
      activity_title: 'Payment Updated',
      module_num: values.payment_num,
      amount: round(values.amount_received),
      description: 'Invoice payment details modified',
    };

    const res = await axios.put(
      `${API_URL}/api/accounting/sales/paymentsReceived/${values.id}/`,
      editedPyamentReceived,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Updated' }));
    dispatch({ type: EDIT_PAYMENT_RECEIVED, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET PAYMENT RECEIVED DOCS
export const getPaymentReceivedDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentReceived/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PAYMENT_RECEIVED_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD PAYMENT RECEIVED FILE
export const uploadPaymentReceivedFile =
  values => async (dispatch, getState) => {
    const formData = new FormData();
    formData.append(
      'doc_file',
      values.doc_file.file,
      values.doc_file.file.name
    );
    formData.append('doc_type', values.doc_file.file.type);
    formData.append('doc_name', values.doc_file.file.name);
    formData.append('doc_size_bytes', values.doc_file.file.size);

    const config = tokenConfig(getState);
    config.headers['Content-Type'] = 'multipart/form-data';
    try {
      const res = await axios.post(
        `${API_URL}/api/accounting/sales/paymentReceived/${values.id}/uploadDoc`,
        formData,
        config
      );
      dispatch(createMessage({ message: 'File Uploaded' }));
      dispatch({ type: ADD_PAYMENT_RECEIVED_DOCS, payload: res.data });
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch(returnErrors('An unexpected error occured', 500));
    }
  };

// DELETE PAYMENT RECEIVED DOC
export const deletePaymentReceivedDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/paymentReceived/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Received Doc Deleted' }));
    dispatch({ type: DELETE_PAYMENT_RECEIVED_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PAYMENT RECEIVED JOURNAL
export const getPaymentReceivedJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentsReceived/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PAYMENT_RECEIVED_JOURNAL, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR PAYMENT RECEIVED JOURNAL
export const clearPaymentReceivedJournal = () => ({
  type: CLEAR_PAYMENT_RECEIVED_JOURNAL,
});

export const applyExcessPayment = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestInvoicePaymentNum());
    let lastNum = latestNum.latest_num || 0;

    let payments = [];
    if (values.type === 'Excess Payment') {
      payments = values.unpaid_invoices
        .filter(item => item.amount_applied > 0)
        .map(invoice => {
          if (invoice.invoice_num === 'Account Opening Balance') {
            return {
              sales_account: invoice.id,
              amount_applied: invoice.amount_applied,
              transaction_num: ++lastNum,
              payment_received: values.payment_id,
            };
          }
          return {
            invoice_id: invoice.id,
            amount_applied: invoice.amount_applied,
            transaction_num: ++lastNum,
            payment_received: values.payment_id,
          };
        });
    } else {
      payments = values.unpaid_invoices
        .filter(item => item.amount_applied > 0)
        .map(invoice => ({
          invoice_id: invoice.id,
          amount_applied: invoice.amount_applied,
          transaction_num: ++lastNum,
          sales_account: values.sales_account_id,
        }));
    }

    const data = { payment_vouchers: payments };

    let invoiceNums = '';

    invoiceNums = values.unpaid_invoices
      .filter(invoice => invoice.amount_applied > 0)
      .reduce(
        (acc, val) =>
          val.invoice_prefix
            ? `${acc}${val.invoice_num}-${val.customer_type_suffix}-${val.invoice_prefix}, `
            : `${acc}${val.invoice_num}, `,
        ''
      );

    let newActivityLog = {
      module_id: values.id,
      customer_id: values.sales_account_id,
      activity_type: 'Payment Received',
      activity_title: 'Excess Payment Updated',
      module_num: values.payment_id,
      amount: values.grand_total,
      description: `Excess Payment of amount ${values.currency}${values.total_credit_applied} applied to invoice# ${invoiceNums}`,
    };

    if (values.type === 'Account Opening Balance') {
      newActivityLog = {
        module_id: values.id,
        customer_id: values.sales_account_id,
        activity_type: 'Opening Balance',
        activity_title: 'Opening Balance Updated',
        module_num: values.payment_id,
        amount: values.grand_total,
        description: `Opening Balance of amount ${values.currency}${values.total_credit_applied} applied to invoice# ${invoiceNums}`,
      };
    }

    await axios.post(
      `${API_URL}/api/accounting/sale/paymentVoucher/amountapply`,
      data,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Credits Applied Successfully' }));
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};

export const getRefundPayments = id => async (dispatch, getState) => {
  try {
    const params = {
      payment_id: id,
    };
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/payments_received/refunds/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENT_RECEIVED_REFUNDS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// REFUND EXCESS PAYMENT
export const refundExcessPayment = values => async (dispatch, getState) => {
  try {
    // const latestNum = await dispatch(getLatestInvoicePaymentNum());
    // let lastNum = latestNum.latest_num || 0;

    const data = {
      payment_received: values.payment_id,
      payment_mode: values.payment_mode,
      from_account_id: values.from_account_id,
      amount_applied: values.amount_applied,
      reference_num: values.reference_num,
      refunded_on: moment(values.refunded_on).format('YYYY-MM-DD'),
      description: values.description,
      // transaction_num: ++lastNum,
    };

    const res = await axios.patch(
      `${API_URL}/api/sale/payment/refund`,
      data,
      tokenConfig(getState)
    );
    dispatch({ type: REFUND_PAYAMENT_RECEIVED, payload: res.data });
    dispatch(createMessage({ message: 'Payment Refunded Successfully' }));
    dispatch(getPaymentReceived(values.payment_id));
    dispatch(getRefundPayments(values.payment_id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editRefundPayment = values => async (dispatch, getState) => {
  try {
    const data = {
      payment_received: values.id,
      payment_mode: values.payment_mode,
      from_account_id: values.from_account_id,
      amount_applied: values.amount_applied,
      //  reference_num: values.reference_num,
      refunded_on: moment(values.refunded_on).format('YYYY-MM-DD'),
      description: values.description,
    };
    await axios.put(
      `${API_URL}/api/accounting/sales/payments_received/refunds/${values.payment_id}/`,
      data,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Updated Successfully' }));
    dispatch(getRefundPayments(values.id));
    dispatch(getPaymentReceived(values.id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const deleteRefundPayment = values => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/payments_received/refunds/${values.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Deleted Successfully' }));
    dispatch(getRefundPayments(values.payment_received));
    dispatch(getPaymentReceived(values.payment_received));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
