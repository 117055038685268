import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import PrivateRoute from '../Common/PrivateRoute';
import Unauthorized from '../ErrorPages/Unauthorized';
import WrappedRoutes from './WrappedRoutes';

const Dashboard = lazy(() => import('../Dashboard'));
const LogIn = lazy(() => import('../Auth/LogIn/index'));
const ChangePassword = lazy(() => import('../Auth/ChangePassword/index'));
const ForgotPassword = lazy(() => import('../Auth/ForgotPassword/index'));
const EditCompanyHolidayPage = lazy(() => import('../HR/CompanyHolidays/Edit'));
const AddCompanyHolidayPage = lazy(() => import('../HR/CompanyHolidays/Add'));
const CompanyHolidaysMainPage = lazy(() =>
  import('../HR/CompanyHolidays/Main')
);
const EditCompanyProfilePage = lazy(() => import('../HR/CompanyProfiles/Edit'));
const AddCompanyProfilePage = lazy(() => import('../HR/CompanyProfiles/Add'));
const CompanyProfilesMainPage = lazy(() =>
  import('../HR/CompanyProfiles/Main')
);
const CompanyFilesPage = lazy(() => import('../HR/CompanyFiles/index'));
const EmployeesPage = lazy(() => import('../HR/Employees/index'));
const LeavesPage = lazy(() => import('../HR/Leaves/index'));
const EmployeeDocumentRequestsPage = lazy(() =>
  import('../HR/EmployeeDocumentRequests/index')
);
const SalariesPage = lazy(() => import('../HR/Salaries/index'));
const EmployeeProfilePage = lazy(() => import('../HR/EmployeeProfile/index'));
const AddEmployeePage = lazy(() => import('../HR/AddEmployee/index'));
const StorageMainPage = lazy(() => import('../Storage/Main/index'));
const StorageTrashPage = lazy(() => import('../Storage/Trash/index'));

// Items
const ItemsMainPage = lazy(() => import('../Accounting/Items/Main/index'));
const ItemsAddPage = lazy(() => import('../Accounting/Items/Add/index'));
const ItemsEditPage = lazy(() => import('../Accounting/Items/Edit/index'));
const ItemsDetailPage = lazy(() => import('../Accounting/Items/Detail/index'));

// Inventory Adjustments
const InventoryAdjustmentsMainPage = lazy(() =>
  import('../Accounting/InventoryAdjustments/Main/index')
);
const InventoryAdjustmentsDetailPage = lazy(() =>
  import('../Accounting/InventoryAdjustments/Detail/index')
);
const InventoryAdjustmentsAddPage = lazy(() =>
  import('../Accounting/InventoryAdjustments/Add/index')
);
const InventoryAdjustmentsEditPage = lazy(() =>
  import('../Accounting/InventoryAdjustments/Edit/index')
);

// Banking
const BankingPage = lazy(() => import('../Accounting/Banking/index'));
const AddBankPage = lazy(() => import('../Accounting/Banking/Add/index'));
const EditBankPage = lazy(() => import('../Accounting/Banking/Edit/index'));
const BankTransactionMain = lazy(() =>
  import('../Accounting/Banking/BankTransactions/Detail')
);
const ImportBankTransactionStatements = lazy(() =>
  import('../Accounting/Banking/BankTransactions/Add')
);

// Customers Routes
const CustomerMainPage = lazy(() =>
  import('../Accounting/Sales/Customers/Main/index')
);
const CustomerAddPage = lazy(() => import('../Accounting/Sales/Customers/Add'));
const CustomerEditPage = lazy(() =>
  import('../Accounting/Sales/Customers/Edit')
);
const CustomerDetailPage = lazy(() =>
  import('../Accounting/Sales/Customers/Detail')
);

// Accounts Routes
const SalesAccountMainPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Main/index')
);
const SalesAccountAddPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Add/index')
);
const SalesAccountEditPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Edit/index')
);
const SalesAccountDetailPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Detail/index')
);
const SalesAccountEmailPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Email/index')
);
const SalesAccountContactsAddPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Contacts/Add/index')
);
const SalesAccountContactsEditPage = lazy(() =>
  import('../Accounting/Sales/Accounts/Contacts/Edit/index')
);
const SuppliersMainPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Main/index')
);
const SuppliersAddPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Add/index')
);
const SuppliersEditPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Edit/index')
);
const SuppliersDetailPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Detail/index')
);
const SupplierEmailPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Email/index')
);
const SuppliersContactsAddPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Contacts/Add/index')
);
const SuppliersContactsEditPage = lazy(() =>
  import('../Accounting/Purchases/Suppliers/Contacts/Edit/index')
);
const ExpensesMainPage = lazy(() =>
  import('../Accounting/Purchases/Expenses/Main/index')
);
const ExpensesAddPage = lazy(() =>
  import('../Accounting/Purchases/Expenses/Add/index')
);
const ExpensesEditPage = lazy(() =>
  import('../Accounting/Purchases/Expenses/Edit/index')
);
const ExpensesDetailPage = lazy(() =>
  import('../Accounting/Purchases/Expenses/Detail/index')
);
const QuotationsMainPage = lazy(() =>
  import('../Accounting/Sales/Quotations/Main/index')
);
const QuotationsAddPage = lazy(() =>
  import('../Accounting/Sales/Quotations/Add/index')
);
const QuotationsEditPage = lazy(() =>
  import('../Accounting/Sales/Quotations/Edit/index')
);
const QuotationsDetailPage = lazy(() =>
  import('../Accounting/Sales/Quotations/Detail/index')
);
const ProInvoicesMainPage = lazy(() =>
  import('../Accounting/Sales/ProInvoices/Main/index')
);
const ProInvoicesAddPage = lazy(() =>
  import('../Accounting/Sales/ProInvoices/Add/index')
);
const ProInvoicesEditPage = lazy(() =>
  import('../Accounting/Sales/ProInvoices/Edit/index')
);
const ProInvoicesDetailPage = lazy(() =>
  import('../Accounting/Sales/ProInvoices/Detail/index')
);
const PaymentsReceivedAddPage = lazy(() =>
  import('../Accounting/Sales/PaymentsReceived/Add/index')
);
const PaymentsReceivedEditPage = lazy(() =>
  import('../Accounting/Sales/PaymentsReceived/Edit/index')
);
const PaymentsReceivedMainPage = lazy(() =>
  import('../Accounting/Sales/PaymentsReceived/Main/index')
);
const PaymentsReceivedDetailPage = lazy(() =>
  import('../Accounting/Sales/PaymentsReceived/Detail/index')
);
const CreditNotesAddPage = lazy(() =>
  import('../Accounting/Sales/CreditNotes/Add/index')
);
const CreditNotesEditPage = lazy(() =>
  import('../Accounting/Sales/CreditNotes/Edit/index')
);
const CreditNotesMainPage = lazy(() =>
  import('../Accounting/Sales/CreditNotes/Main/index')
);
const CreditNotesDetailPage = lazy(() =>
  import('../Accounting/Sales/CreditNotes/Detail/index')
);
const InvoicesMainPage = lazy(() =>
  import('../Accounting/Sales/Invoices/Main/index')
);
const InvoicesAddPage = lazy(() =>
  import('../Accounting/Sales/Invoices/Add/index')
);
const InvoicesEditPage = lazy(() =>
  import('../Accounting/Sales/Invoices/Edit/index')
);
const InvoicesDetailPage = lazy(() =>
  import('../Accounting/Sales/Invoices/Detail/index')
);
const PurOrdersMainPage = lazy(() =>
  import('../Accounting/Purchases/PurOrders/Main/index')
);
const PurOrdersAddPage = lazy(() =>
  import('../Accounting/Purchases/PurOrders/Add/index')
);
const PurOrdersEditPage = lazy(() =>
  import('../Accounting/Purchases/PurOrders/Edit/index')
);
const PurOrdersDetailPage = lazy(() =>
  import('../Accounting/Purchases/PurOrders/Detail/index')
);
const BillsMainPage = lazy(() =>
  import('../Accounting/Purchases/Bills/Main/index')
);
const BillsAddPage = lazy(() =>
  import('../Accounting/Purchases/Bills/Add/index')
);
const BillsEditPage = lazy(() =>
  import('../Accounting/Purchases/Bills/Edit/index')
);
const BillsDetailPage = lazy(() =>
  import('../Accounting/Purchases/Bills/Detail/index')
);
const PaymentsMadeMainPage = lazy(() =>
  import('../Accounting/Purchases/PaymentsMade/Main/index')
);
const PaymentsMadeAddPage = lazy(() =>
  import('../Accounting/Purchases/PaymentsMade/Add/index')
);
const PaymentsMadeEditPage = lazy(() =>
  import('../Accounting/Purchases/PaymentsMade/Edit/index')
);
const PaymentsMadeDetailPage = lazy(() =>
  import('../Accounting/Purchases/PaymentsMade/Detail/index')
);
const SupplierCreditsMainPage = lazy(() =>
  import('../Accounting/Purchases/SupplierCredits/Main/index')
);
const SupplierCreditsAddPage = lazy(() =>
  import('../Accounting/Purchases/SupplierCredits/Add/index')
);
const SupplierCreditsEditPage = lazy(() =>
  import('../Accounting/Purchases/SupplierCredits/Edit/index')
);
const SupplierCreditsDetailPage = lazy(() =>
  import('../Accounting/Purchases/SupplierCredits/Detail/index')
);
const CurrenciesMainPage = lazy(() =>
  import('../Accounting/Accountant/Currencies/Main/index')
);
const CurrenciesDetailPage = lazy(() =>
  import('../Accounting/Accountant/Currencies/Detail/index')
);
const CurrenciesEditPage = lazy(() =>
  import('../Accounting/Accountant/Currencies/Edit/index')
);
const CurrenciesRatesAddPage = lazy(() =>
  import('../Accounting/Accountant/Currencies/Rates/Add/index')
);
const CurrenciesRatesEditPage = lazy(() =>
  import('../Accounting/Accountant/Currencies/Rates/Edit/index')
);
const ChartOfAccountsDetailPage = lazy(() =>
  import('../Accounting/Accountant/ChartOfAccounts/Detail/index')
);
const ChartOfAccountsEditPage = lazy(() =>
  import('../Accounting/Accountant/ChartOfAccounts/Edit/index')
);
const ChartOfAccountsAddPage = lazy(() =>
  import('../Accounting/Accountant/ChartOfAccounts/Add/index')
);
const ChartOfAccountsMainPage = lazy(() =>
  import('../Accounting/Accountant/ChartOfAccounts/Main/index')
);
const JournalsAddPage = lazy(() =>
  import('../Accounting/Accountant/ManualJournals/Add/index')
);
const JournalsEditPage = lazy(() =>
  import('../Accounting/Accountant/ManualJournals/Edit/index')
);
const JournalsDetailPage = lazy(() =>
  import('../Accounting/Accountant/ManualJournals/Detail/index')
);
const JournalsMainPage = lazy(() =>
  import('../Accounting/Accountant/ManualJournals/Main/index')
);
const TaxPaymentsMainPage = lazy(() =>
  import('../Accounting/Accountant/TaxPayments/index')
);
const TaxAdjustmentsMainPage = lazy(() =>
  import('../Accounting/Accountant/TaxAdjustments/index')
);
const CurrencyAdjustmentsMainPage = lazy(() =>
  import('../Accounting/Accountant/CurrencyAdjustments/Main/index')
);
const CurrencyAdjustmentsDetailPage = lazy(() =>
  import('../Accounting/Accountant/CurrencyAdjustments/Detail/index')
);
const TransactionsLocking = lazy(() =>
  import('../Accounting/Accountant/TransactionsLocking/index')
);
const AccountingSalaries = lazy(() => import('../Accounting/Salaries/index'));

const ManageActivities = lazy(() => import('../ManageActivities/index'));
const InternalMeetings = lazy(() => import('../InternalMeetings/index'));
const Announcements = lazy(() => import('../Announcements/Main/index'));
const AnnouncementDetail = lazy(() => import('../Announcements/Detail/Index'));

const SettingsMainPage = lazy(() => import('../Settings/Main/index'));
const SettingsAddPage = lazy(() => import('../Settings/Add/index'));
const SettingsEditPage = lazy(() => import('../Settings/Edit/index'));
const Calendar = lazy(() => import('../User/Calendar/index'));
const Profile = lazy(() => import('../User/Profile/index'));

// Operations
const EventCalender = lazy(() => import('../Operations/EventCalendar'));
const EventCoordinator = lazy(() => import('../Operations/EventCoordinator'));
const RequestedEvents = lazy(() => import('../Operations/Requested'));
const ConfirmedEvents = lazy(() => import('../Operations/Confirmed'));
const CancelledEvents = lazy(() => import('../Operations/Cancelled'));
const FinishedEvents = lazy(() => import('../Operations/Finished'));
const ProductLaunch = lazy(() => import('../Operations/ProductLaunch'));
const ServiceRequestsMain = lazy(() =>
  import('../Operations/ServiceRequests/Main')
);
const ServiceRequestDetails = lazy(() =>
  import('../Operations/ServiceRequests/Detail')
);
const EmailSettings = lazy(() => import('../EmailSettings'));

// Reports

const ReportsMainPage = lazy(() => import('../Reports'));
const ProfitAndLoss = lazy(() =>
  import('../Reports/ReportTypes/ProfitAndLoss')
);
const TaxReturns = lazy(() => import('../Reports/ReportTypes/TaxReturns'));
const VATReturnDetails = lazy(() =>
  import('../Reports/ReportTypes/VATReturnDetails')
);
const VATReturnDetailTransactions = lazy(() =>
  import('../Reports/ReportTypes/VATReturnDetailTransactions')
);
const VATAudit = lazy(() => import('../Reports/ReportTypes/VATAudit'));
const PaymentsReceivedReport = lazy(() =>
  import('../Reports/ReportTypes/PaymentsReceived')
);
const TimetoGetPaidReport = lazy(() =>
  import('../Reports/ReportTypes/TimeToGetPaid')
);
const CreditNoteDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/CreditNoteDetails')
);
const RefundHistoryReport = lazy(() =>
  import('../Reports/ReportTypes/RefundHistory')
);
const PurchasesBySupplier = lazy(() =>
  import('../Reports/ReportTypes/PurchasesBySupplier')
);
const PurchasesBySupplierDetails = lazy(() =>
  import('../Reports/ReportTypes/PurchasesBySupplierDetails')
);
const PurchasesByItem = lazy(() =>
  import('../Reports/ReportTypes/PurchasesByItem')
);
const PurchasesByItemDetails = lazy(() =>
  import('../Reports/ReportTypes/PurchasesByItemDetails')
);
const PurchasesByClientType = lazy(() =>
  import('../Reports/ReportTypes/PurchasesByClientType')
);
const ExpenseDetails = lazy(() =>
  import('../Reports/ReportTypes/ExpenseDetails')
);
const ExpensesByCategory = lazy(() =>
  import('../Reports/ReportTypes/ExpensesByCategory')
);
const ExpensesByCategoryDetails = lazy(() =>
  import('../Reports/ReportTypes/ExpensesByCategoryDetails')
);
const ActivityLogsReport = lazy(() =>
  import('../Reports/ReportTypes/ActivityLogs')
);
const ExceptionReport = lazy(() =>
  import('../Reports/ReportTypes/ExceptionReport')
);
const RealizedGainOrLossReport = lazy(() =>
  import('../Reports/ReportTypes/RealizedGainOrLoss')
);
const UnRealizedGainOrLossReport = lazy(() =>
  import('../Reports/ReportTypes/UnRealizedGainOrLoss')
);
const BillDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/BillDetails')
);
const SupplierCreditsDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/SupplierCreditDetails')
);
const PaymentsMadeReport = lazy(() =>
  import('../Reports/ReportTypes/PaymentsMade')
);
const SupplierRefundHistoryReport = lazy(() =>
  import('../Reports/ReportTypes/SupplierRefundHistory')
);
const PurchaseOrderDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/PurchaseOrderDetails')
);
const PurchaseOrdersBySuppliersReport = lazy(() =>
  import('../Reports/ReportTypes/PurchaseOrdersBySuppliers')
);
const PurchaseOrdersBySupplierDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/PurchaseOrdersBySupplierDetails')
);
const PayableSumaryReport = lazy(() =>
  import('../Reports/ReportTypes/PayableSummary')
);
const PayableDetailsReport = lazy(() =>
  import('../Reports/ReportTypes/PayableDetails')
);
const AccountTransactionsReport = lazy(() =>
  import('../Reports/ReportTypes/AccountTransactions')
);
const AccountTypeSummaryReport = lazy(() =>
  import('../Reports/ReportTypes/AccountTypeSummary')
);
const GeneralLedgerReport = lazy(() =>
  import('../Reports/ReportTypes/GeneralLedger')
);
const DetailedGeneralLedgerReport = lazy(() =>
  import('../Reports/ReportTypes/DetailedGeneralLedger')
);
const JournalReport = lazy(() =>
  import('../Reports/ReportTypes/JournalReport')
);
const TrialBalanceReport = lazy(() =>
  import('../Reports/ReportTypes/TrialBalance')
);
const CashFlowStatement = lazy(() =>
  import('../Reports/ReportTypes/CashFlowStatement')
);
const BalanceSheet = lazy(() => import('../Reports/ReportTypes/BalanceSheet'));
const MovementOfEquity = lazy(() =>
  import('../Reports/ReportTypes/MovementOfEquity')
);
const BusinessPerformanceRatios = lazy(() =>
  import('../Reports/ReportTypes/BusinessPerformanceRatios')
);
const SalesByCustomer = lazy(() =>
  import('../Reports/ReportTypes/SalesByCustomer')
);
const SalesByCustomerDetail = lazy(() =>
  import('../Reports/ReportTypes/SalesByCustomerDetail')
);
const SalesByItem = lazy(() => import('../Reports/ReportTypes/SalesByItem'));
const SalesByItemDetail = lazy(() =>
  import('../Reports/ReportTypes/SalesByItemDetail')
);
const SalesBySalesPerson = lazy(() =>
  import('../Reports/ReportTypes/SalesBySalesPerson')
);
const SalesBySalesPersonDetail = lazy(() =>
  import('../Reports/ReportTypes/SalesBySalesPersonDetail')
);
const SalesBySaleType = lazy(() =>
  import('../Reports/ReportTypes/SalesBySaleType')
);
const AccountTransactionDetail = lazy(() =>
  import('../Reports/ReportTypes/AccountTransactionDetail')
);
const CustomerBalances = lazy(() =>
  import('../Reports/ReportTypes/CustomerBalances')
);
const CustomerBalancesDetail = lazy(() =>
  import('../Reports/ReportTypes/CustomerBalancesDetail')
);
const CustomerBalanceCreditDetail = lazy(() =>
  import('../Reports/ReportTypes/CustomerBalanceCreditDetail')
);
const CustomerBalanceInvoiceDetail = lazy(() =>
  import('../Reports/ReportTypes/CustomerBalanceInvoiceDetail')
);
const InvoiceDetails = lazy(() =>
  import('../Reports/ReportTypes/InvoiceDetails')
);
const ArAgingSummary = lazy(() =>
  import('../Reports/ReportTypes/ArAgingSummary')
);
const ArAgingSummaryDetail = lazy(() =>
  import('../Reports/ReportTypes/ArAgingSummaryDetail')
);
const ArAgingDetails = lazy(() =>
  import('../Reports/ReportTypes/ArAgingDetails')
);
const ReceivableDetails = lazy(() =>
  import('../Reports/ReportTypes/ReceivableDetails')
);
const ReceivableSummary = lazy(() =>
  import('../Reports/ReportTypes/ReceivableSummary')
);
const SupplierBalances = lazy(() =>
  import('../Reports/ReportTypes/SupplierBalances')
);
const SupplierBalancesDetail = lazy(() =>
  import('../Reports/ReportTypes/SupplierBalancesDetail')
);
const SupplierBalancesBillDetail = lazy(() =>
  import('../Reports/ReportTypes/SupplierBalancesBillDetail')
);
const SupplierBalancesExcessPaymentDetail = lazy(() =>
  import('../Reports/ReportTypes/SupplierBalancesExcessPaymentDetail')
);
const ApAgingSummary = lazy(() =>
  import('../Reports/ReportTypes/ApAgingSummary')
);
const ApAgingSummaryDetail = lazy(() =>
  import('../Reports/ReportTypes/ApAgingSummaryDetail')
);
const ApAgingDetails = lazy(() =>
  import('../Reports/ReportTypes/ApAgingDetails')
);
const InventorySummary = lazy(() =>
  import('../Reports/ReportTypes/InventorySummary')
);
const GrossProfitRatio = lazy(() =>
  import('../Reports/ReportTypes/GrossProfit')
);
const CommittedStockDetails = lazy(() =>
  import('../Reports/ReportTypes/CommittedStockDetails')
);
const InventoryValuationSummaryDetails = lazy(() =>
  import('../Reports/ReportTypes/InventoryValuationSummaryDetails')
);
const InventoryValuationSummary = lazy(() =>
  import('../Reports/ReportTypes/InventoryValuationSummary')
);
const FIFOCostLotTracking = lazy(() =>
  import('../Reports/ReportTypes/FIFOCostLotTracking')
);
const InventoryAgingSummary = lazy(() =>
  import('../Reports/ReportTypes/InventoryAgingSummary')
);
const ProductSalesReport = lazy(() =>
  import('../Reports/ReportTypes/ProductSalesReport')
);
const StockSummaryReport = lazy(() =>
  import('../Reports/ReportTypes/StockSummaryReport')
);
const ABCClassification = lazy(() =>
  import('../Reports/ReportTypes/ABCClassification')
);

// Opening Balance
const OpeningBalanceMain = lazy(() =>
  import('../Accounting/OpeningBalance/Main')
);
const OpeningBalanceSetup = lazy(() =>
  import('../Accounting/OpeningBalance/Edit')
);

// Stamp and signature
const StampAndSignature = lazy(() => import('../Accounting/StampAndSignature'));
// const UploadSignature = lazy(() =>
//   import('../Accounting/StampAndSignature/components/RequestorSignature/index')
// );

// GlobalDocuments
const GlobalDocumentsPreviewer = lazy(() =>
  import('../GlobalDocuments/Preview')
);
const GlobalDocumentsEditor = lazy(() => import('../GlobalDocuments/Edit'));

// user accessibility

const AccountingEmployeeMain = lazy(() =>
  import('../UserSystemAccessibility/Main')
);
const EditEmployeeAccess = lazy(() =>
  import('../UserSystemAccessibility/Edit')
);

const Pages = () => (
  <Switch>
    <Route path="/pages/user/calendar" component={Calendar} />
    <Route path="/pages/user/profile" component={Profile} />
    <Route path="/pages/operations/email-settings" component={EmailSettings} />
    <Route
      path="/pages/hr/companyHolidays/:id/edit"
      component={EditCompanyHolidayPage}
    />
    <Route
      path="/pages/hr/companyHolidays/add"
      component={AddCompanyHolidayPage}
    />
    <Route
      path="/pages/hr/companyHolidays"
      component={CompanyHolidaysMainPage}
    />
    <Route
      path="/pages/hr/companyProfiles/:id/edit"
      component={EditCompanyProfilePage}
    />
    <Route
      path="/pages/hr/companyProfiles/add"
      component={AddCompanyProfilePage}
    />
    <Route
      path="/pages/hr/companyProfiles"
      component={CompanyProfilesMainPage}
    />
    <Route path="/pages/hr/companyFiles" component={CompanyFilesPage} />
    <Route
      path="/pages/hr/employees/:id/profile"
      component={EmployeeProfilePage}
    />
    <Route path="/pages/hr/employees/add" component={AddEmployeePage} />
    <Route path="/pages/hr/employees" component={EmployeesPage} />
    <Route path="/pages/hr/leaves" component={LeavesPage} />
    <Route
      path="/pages/hr/employeeRequests"
      component={EmployeeDocumentRequestsPage}
    />
    <Route path="/pages/hr/salaries" component={SalariesPage} />
    <Route path="/pages/storage/trash" component={StorageTrashPage} />
    <Route path="/pages/employee/leaves" component={LeavesPage} />
    <Route
      exact
      path="/pages/accounting/items/:id/edit"
      component={ItemsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/items/:itemID/detail"
      component={ItemsDetailPage}
    />
    <Route exact path="/pages/accounting/items/add" component={ItemsAddPage} />
    <Route path="/pages/accounting/items" component={ItemsMainPage} />
    <Route
      path="/pages/accounting/inventoryAdjustments/:adjustmentID/detail"
      component={InventoryAdjustmentsDetailPage}
    />
    <Route
      path="/pages/accounting/inventoryAdjustments/:adjustmentID/edit"
      component={InventoryAdjustmentsEditPage}
    />
    <Route
      path="/pages/accounting/inventoryAdjustments/add"
      component={InventoryAdjustmentsAddPage}
    />
    <Route
      path="/pages/accounting/inventoryAdjustments"
      component={InventoryAdjustmentsMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/contacts/:id/edit"
      component={SalesAccountContactsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/:id/addContact"
      component={SalesAccountContactsAddPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/:id/email"
      component={SalesAccountEmailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/:id/detail"
      component={SalesAccountDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/:id/edit"
      component={SalesAccountEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/accounts/add"
      component={SalesAccountAddPage}
    />
    <Route
      path="/pages/accounting/sales/accounts"
      component={SalesAccountMainPage}
    />
    <Route
      path="/pages/accounting/sales/customers/:id/detail"
      component={CustomerDetailPage}
    />
    <Route
      path="/pages/accounting/sales/customers/:id/edit"
      component={CustomerEditPage}
    />
    <Route
      path="/pages/accounting/sales/customers/add"
      component={CustomerAddPage}
    />
    <Route
      path="/pages/accounting/sales/customers"
      component={CustomerMainPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/contacts/:id/edit"
      component={SuppliersContactsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/:id/addContact"
      component={SuppliersContactsAddPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/:id/email"
      component={SupplierEmailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/:id/detail"
      component={SuppliersDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/:id/edit"
      component={SuppliersEditPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/suppliers/add"
      component={SuppliersAddPage}
    />
    <Route
      path="/pages/accounting/purchases/suppliers"
      component={SuppliersMainPage}
    />
    <Route
      path="/pages/accounting/purchases/expenses/:id/detail"
      component={ExpensesDetailPage}
    />
    <Route
      path="/pages/accounting/purchases/expenses/:id/edit"
      component={ExpensesEditPage}
    />
    <Route
      path="/pages/accounting/purchases/expenses/add"
      component={ExpensesAddPage}
    />
    <Route
      path="/pages/accounting/purchases/expenses"
      component={ExpensesMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/quotations/:quotationId/createInvoice"
      component={InvoicesAddPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/quotations/:id/createProInvoice"
      component={ProInvoicesAddPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/quotations/:id/detail"
      component={QuotationsDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/quotations/:id/edit"
      component={QuotationsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/quotations/add"
      component={QuotationsAddPage}
    />
    <Route
      path="/pages/accounting/sales/quotations"
      component={QuotationsMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/proInvoices/:proInvoiceId/createInvoice"
      component={InvoicesAddPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/proInvoices/:id/detail"
      component={ProInvoicesDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/proInvoices/:id/edit"
      component={ProInvoicesEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/proInvoices/add"
      component={ProInvoicesAddPage}
    />
    <Route
      path="/pages/accounting/sales/proInvoices"
      component={ProInvoicesMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/invoices/:invoiceId/createCreditNotes"
      component={CreditNotesAddPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/invoices/:id/detail"
      component={InvoicesDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/invoices/:id/edit"
      component={InvoicesEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/invoices/add"
      component={InvoicesAddPage}
    />
    <Route
      path="/pages/accounting/sales/invoices"
      component={InvoicesMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/paymentsReceived/:id/detail"
      component={PaymentsReceivedDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/paymentsReceived/:id/edit"
      component={PaymentsReceivedEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/paymentsReceived/add"
      component={PaymentsReceivedAddPage}
    />
    <Route
      path="/pages/accounting/sales/paymentsReceived"
      component={PaymentsReceivedMainPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/creditNotes/:id/detail"
      component={CreditNotesDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/CreditNotes/:id/edit"
      component={CreditNotesEditPage}
    />
    <Route
      exact
      path="/pages/accounting/sales/CreditNotes/add"
      component={CreditNotesAddPage}
    />
    <Route
      path="/pages/accounting/sales/CreditNotes"
      component={CreditNotesMainPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/purOrders/:purOrderId/createBill"
      component={BillsAddPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/purOrders/:id/detail"
      component={PurOrdersDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/purOrders/:id/edit"
      component={PurOrdersEditPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/purOrders/add"
      component={PurOrdersAddPage}
    />
    <Route
      path="/pages/accounting/purchases/purOrders"
      component={PurOrdersMainPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/bills/:billId/createSupplierCredit"
      component={SupplierCreditsAddPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/bills/:id/detail"
      component={BillsDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/bills/:id/edit"
      component={BillsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/bills/add"
      component={BillsAddPage}
    />
    <Route path="/pages/accounting/purchases/bills" component={BillsMainPage} />
    <Route
      exact
      path="/pages/accounting/purchases/paymentsMade/:id/detail"
      component={PaymentsMadeDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/paymentsMade/:id/edit"
      component={PaymentsMadeEditPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/paymentsMade/add"
      component={PaymentsMadeAddPage}
    />
    <Route
      path="/pages/accounting/purchases/paymentsMade"
      component={PaymentsMadeMainPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/supplierCredits/:id/detail"
      component={SupplierCreditsDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/purchases/supplierCredits/:id/edit"
      component={SupplierCreditsEditPage}
    />
    <Route
      path="/pages/accounting/purchases/supplierCredits/add"
      component={SupplierCreditsAddPage}
    />
    <Route
      path="/pages/accounting/purchases/supplierCredits"
      component={SupplierCreditsMainPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/currencies/exchangeRates/:id/edit"
      component={CurrenciesRatesEditPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/currencies/:id/addExchangeRate"
      component={CurrenciesRatesAddPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/currencies/:id/detail"
      component={CurrenciesDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/currencies/:id/edit"
      component={CurrenciesEditPage}
    />
    <Route
      path="/pages/accounting/accountant/currencies"
      component={CurrenciesMainPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/chartOfAccounts/:id/detail"
      component={ChartOfAccountsDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/chartOfAccounts/:id/edit"
      component={ChartOfAccountsEditPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/chartOfAccounts/add"
      component={ChartOfAccountsAddPage}
    />
    <Route
      path="/pages/accounting/accountant/chartOfAccounts"
      component={ChartOfAccountsMainPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/journals/:id/detail"
      component={JournalsDetailPage}
    />
    <Route
      exact
      path="/pages/accounting/accountant/journals/:id/edit"
      component={JournalsEditPage}
    />
    <Route
      excat
      path="/pages/announcements/:id/detail"
      component={AnnouncementDetail}
    />
    <Route
      exact
      path="/pages/accounting/accountant/journals/add"
      component={JournalsAddPage}
    />
    <Route
      path="/pages/accounting/accountant/journals"
      component={JournalsMainPage}
    />
    <Route
      path="/pages/accounting/accountant/taxPayments"
      component={TaxPaymentsMainPage}
    />
    <Route
      path="/pages/accounting/accountant/taxAdjustments"
      component={TaxAdjustmentsMainPage}
    />
    <Route
      path="/pages/accounting/accountant/currencyAdjustments/:id/detail"
      component={CurrencyAdjustmentsDetailPage}
    />
    <Route
      path="/pages/accounting/accountant/currencyAdjustments"
      component={CurrencyAdjustmentsMainPage}
    />
    <Route
      path="/pages/accounting/accountant/transactionLocking"
      component={TransactionsLocking}
    />
    <Route
      path="/pages/accounting/banking/import"
      component={ImportBankTransactionStatements}
    />
    <Route
      path="/pages/accounting/banking/transaction"
      component={BankTransactionMain}
    />
    <Route path="/pages/accounting/banking/:id/edit" component={EditBankPage} />
    <Route path="/pages/accounting/banking/add" component={AddBankPage} />
    <Route path="/pages/accounting/banking" component={BankingPage} />
    <Route
      path="/pages/accounting/openingBalance/setup"
      component={OpeningBalanceSetup}
    />
    <Route
      path="/pages/accounting/openingBalance"
      component={OpeningBalanceMain}
    />
    <Route
      path="/pages/accounting/stampAndSignature"
      component={StampAndSignature}
    />
    {/* <Route
      path="/pages/accounting/uploadSignature"
      component={UploadSignature}
    /> */}
    <Route
      exact
      path="/pages/accessibility/:id/edit"
      component={EditEmployeeAccess}
    />
    <Route
      exact
      path="/pages/accessibility"
      component={AccountingEmployeeMain}
    />

    <Route path="/pages/accounting/salaries" component={AccountingSalaries} />
    <Route path="/pages/storage/main" component={StorageMainPage} />
    <Route path="/pages/manageInternalMeetings" component={InternalMeetings} />
    <Route path="/pages/manageActivities" component={ManageActivities} />
    <Route path="/pages/announcements" component={Announcements} />
    <Route path="/pages/settings/:id/edit" component={SettingsEditPage} />
    <Route path="/pages/settings/add" component={SettingsAddPage} />
    <Route path="/pages/settings" component={SettingsMainPage} />
    <Route path="/pages/operations/eventCalendar" component={EventCalender} />
    <Route
      path="/pages/operations/eventCoordinator"
      component={EventCoordinator}
    />
    <Route
      path="/pages/operations/:rfpID/productLaunch"
      component={ProductLaunch}
    />
    <Route path="/pages/operations/requested" component={RequestedEvents} />
    <Route path="/pages/operations/confirmed" component={ConfirmedEvents} />
    <Route path="/pages/operations/finished" component={FinishedEvents} />
    <Route path="/pages/operations/cancelled" component={CancelledEvents} />
    <Route
      path="/pages/operations/serviceRequests/:requestID/detail"
      component={ServiceRequestDetails}
    />
    <Route
      path="/pages/operations/serviceRequests"
      component={ServiceRequestsMain}
    />
    {/* Reports Routes */}
    <Route path="/pages/reports/apAgingDetails" component={ApAgingDetails} />
    <Route
      path="/pages/reports/apAgingSummary/detail"
      component={ApAgingSummaryDetail}
    />
    <Route path="/pages/reports/apAgingSummary" component={ApAgingSummary} />
    <Route
      path="/pages/reports/supplierBalances/excessPayment/detail"
      component={SupplierBalancesExcessPaymentDetail}
    />
    <Route
      path="/pages/reports/supplierBalances/bill/detail"
      component={SupplierBalancesBillDetail}
    />
    <Route
      path="/pages/reports/supplierBalances/detail"
      component={SupplierBalancesDetail}
    />
    <Route
      path="/pages/reports/supplierBalances"
      component={SupplierBalances}
    />
    <Route
      path="/pages/reports/receivableSummary"
      component={ReceivableSummary}
    />
    <Route
      path="/pages/reports/receivableDetails"
      component={ReceivableDetails}
    />
    <Route path="/pages/reports/arAgingDetails" component={ArAgingDetails} />
    <Route
      path="/pages/reports/arAgingSummary/detail"
      component={ArAgingSummaryDetail}
    />
    <Route path="/pages/reports/arAgingSummary" component={ArAgingSummary} />
    <Route path="/pages/reports/invoiceDetails" component={InvoiceDetails} />
    <Route
      path="/pages/reports/customerBalances/creditNote/detail"
      component={CustomerBalanceCreditDetail}
    />
    <Route
      path="/pages/reports/customerBalances/invoice/detail"
      component={CustomerBalanceInvoiceDetail}
    />
    <Route
      path="/pages/reports/customerBalances/detail"
      component={CustomerBalancesDetail}
    />
    <Route
      path="/pages/reports/customerBalances"
      component={CustomerBalances}
    />
    <Route
      path="/pages/reports/accountTransactions/:id/detail"
      component={AccountTransactionDetail}
    />
    <Route
      path="/pages/reports/salesBySalesPerson/:id/detail"
      component={SalesBySalesPersonDetail}
    />
    <Route
      path="/pages/reports/salesBySalesPerson"
      component={SalesBySalesPerson}
    />
    <Route path="/pages/reports/salesBySalesType" component={SalesBySaleType} />
    <Route
      path="/pages/reports/salesByItem/:name/detail"
      component={SalesByItemDetail}
    />
    <Route path="/pages/reports/salesByItem" component={SalesByItem} />
    <Route
      path="/pages/reports/salesByCustomer/:id/detail"
      component={SalesByCustomerDetail}
    />
    <Route path="/pages/reports/salesByCustomer" component={SalesByCustomer} />
    <Route
      path="/pages/reports/businessPerformanceRatios"
      component={BusinessPerformanceRatios}
    />
    <Route path="/pages/reports/balanceSheet" component={BalanceSheet} />
    <Route
      path="/pages/reports/movementOfEquity"
      component={MovementOfEquity}
    />
    <Route
      path="/pages/reports/cashFlowStatement"
      component={CashFlowStatement}
    />
    <Route path="/pages/reports/profitAndLoss" component={ProfitAndLoss} />
    <Route
      path="/pages/reports/taxReturns/:vatReturnID/transactions"
      component={VATReturnDetailTransactions}
    />
    <Route
      exact
      path="/pages/reports/taxReturns/:vatReturnID"
      component={VATReturnDetails}
    />
    <Route exact path="/pages/reports/taxReturns" component={TaxReturns} />
    <Route exact path="/pages/reports/vatAudit" component={VATAudit} />
    <Route
      exact
      path="/pages/reports/paymentsReceived"
      component={PaymentsReceivedReport}
    />
    <Route
      exact
      path="/pages/reports/timeToGetPaid"
      component={TimetoGetPaidReport}
    />
    <Route
      exact
      path="/pages/reports/creditNoteDetails"
      component={CreditNoteDetailsReport}
    />
    <Route
      exact
      path="/pages/reports/refundHistory"
      component={RefundHistoryReport}
    />
    <Route
      exact
      path="/pages/reports/purchasesBySupplier/details"
      component={PurchasesBySupplierDetails}
    />
    <Route
      exact
      path="/pages/reports/purchasesBySupplier"
      component={PurchasesBySupplier}
    />
    <Route
      exact
      path="/pages/reports/purchasesByItem"
      component={PurchasesByItem}
    />
    <Route
      exact
      path="/pages/reports/purchasesByItem/details"
      component={PurchasesByItemDetails}
    />
    <Route
      exact
      path="/pages/reports/puchasesByClientType"
      component={PurchasesByClientType}
    />
    <Route
      exact
      path="/pages/reports/expenseDetails"
      component={ExpenseDetails}
    />
    <Route
      exact
      path="/pages/reports/expensesByCategory/details"
      component={ExpensesByCategoryDetails}
    />
    <Route
      exact
      path="/pages/reports/expensesByCategory"
      component={ExpensesByCategory}
    />
    <Route
      exact
      path="/pages/reports/activityLogs"
      component={ActivityLogsReport}
    />
    <Route
      exact
      path="/pages/reports/exceptionReport"
      component={ExceptionReport}
    />
    <Route
      exact
      path="/pages/reports/realizedGainOrLoss"
      component={RealizedGainOrLossReport}
    />
    <Route
      exact
      path="/pages/reports/unrealizedGainOrLoss"
      component={UnRealizedGainOrLossReport}
    />
    <Route
      exact
      path="/pages/reports/billDetails"
      component={BillDetailsReport}
    />
    <Route
      exact
      path="/pages/reports/supplierCreditDetails"
      component={SupplierCreditsDetailsReport}
    />
    <Route
      exact
      path="/pages/reports/paymentsMade"
      component={PaymentsMadeReport}
    />
    <Route
      exact
      path="/pages/reports/supplierRefundHistory"
      component={SupplierRefundHistoryReport}
    />
    <Route
      exact
      path="/pages/reports/purchaseOrderDetails"
      component={PurchaseOrderDetailsReport}
    />
    <Route
      exact
      path="/pages/reportspurchaseOrdersBySuppliers/details"
      component={PurchaseOrdersBySupplierDetailsReport}
    />
    <Route
      exact
      path="/pages/reports/purchaseOrdersBySuppliers"
      component={PurchaseOrdersBySuppliersReport}
    />
    <Route
      exact
      path="/pages/reports/payableSummary"
      component={PayableSumaryReport}
    />
    <Route
      exact
      path="/pages/reports/payableDetails"
      component={PayableDetailsReport}
    />
    <Route
      exact
      path="/pages/reports/accountTransactions"
      component={AccountTransactionsReport}
    />
    <Route
      exact
      path="/pages/reports/accountTypeSummary"
      component={AccountTypeSummaryReport}
    />
    <Route
      exact
      path="/pages/reports/generalLedger"
      component={GeneralLedgerReport}
    />
    <Route
      exact
      path="/pages/reports/detailedGeneralLedger"
      component={DetailedGeneralLedgerReport}
    />
    <Route
      exact
      path="/pages/reports/journalReport"
      component={JournalReport}
    />
    <Route
      exact
      path="/pages/reports/trialBalance"
      component={TrialBalanceReport}
    />
    <Route
      exact
      path="/pages/reports/grossProfitRatio"
      component={GrossProfitRatio}
    />
    <Route
      exact
      path="/pages/reports/inventorySummary"
      component={InventorySummary}
    />
    <Route
      exact
      path="/pages/reports/committedStockDetails"
      component={CommittedStockDetails}
    />
    <Route
      exact
      path="/pages/reports/inventoryValuationSummaryDetails"
      component={InventoryValuationSummaryDetails}
    />
    <Route
      exact
      path="/pages/reports/inventoryValuationSummary"
      component={InventoryValuationSummary}
    />
    <Route
      exact
      path="/pages/reports/fifoCostLotTracking"
      component={FIFOCostLotTracking}
    />
    <Route
      exact
      path="/pages/reports/inventoryAgingSummary"
      component={InventoryAgingSummary}
    />
    <Route
      exact
      path="/pages/reports/productSalesReport"
      component={ProductSalesReport}
    />
    <Route
      exact
      path="/pages/reports/stockSummaryReport"
      component={StockSummaryReport}
    />
    <Route
      exact
      path="/pages/reports/abcClassification"
      component={ABCClassification}
    />
    <Route path="/pages/reports" component={ReportsMainPage} />
    <Route
      path="/pages/global/documents/preview"
      component={GlobalDocumentsPreviewer}
    />
    <Route
      path="/pages/global/documents/edit"
      component={GlobalDocumentsEditor}
    />
    <Route exact path="/pages/unauthorized" component={Unauthorized} />
  </Switch>
);

const wrappedRoutes = () => {
  return <WrappedRoutes Pages={Pages} Dashboard={Dashboard} />;
};

const Router = () => (
  <MainWrapper>
    <main>
      <Suspense
        fallback={
          true && (
            <div className="load">
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path
                    fill="#e0c46cde"
                    d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"
                  />
                </svg>
              </div>
            </div>
          )
        }
      >
        <Switch>
          <Route exact path="/login" component={LogIn} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword" component={ChangePassword} />
          <PrivateRoute path="/" component={wrappedRoutes} />
        </Switch>
      </Suspense>
    </main>
  </MainWrapper>
);

export default Router;
