/* eslint-disable no-plusplus */
/* eslint-disable max-len */

import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addActivityLog } from './salesAccountsActions';
import formatAmount from '../../utils/formatAmount';
import groupArrayOfObjects from '../../utils/groupArrayOfObjects';

export const GET_CREDIT_NOTES_LIST = 'GET_CREDIT_NOTES_LIST';
export const GET_CREDIT_NOTE = 'GET_CREDIT_NOTE';
export const CLEAR_CREDIT_NOTE = 'CLEAR_CREDIT_NOTE';
export const GET_CREDIT_NOTES = 'GET_CREDIT_NOTES';
export const ADD_CREDIT_NOTE = 'ADD_CREDIT_NOTE';
export const DELETE_CREDIT_NOTE = 'DELETE_CREDIT_NOTE';
export const EDIT_CREDIT_NOTE = 'EDIT_CREDIT_NOTE';
export const GET_CREDIT_NOTE_DOCS = 'GET_CREDIT_NOTE_DOCS';
export const ADD_CREDIT_NOTE_DOCS = 'ADD_CREDIT_NOTE_DOCS';
export const DELETE_CREDIT_NOTE_DOC = 'DELETE_CREDIT_NOTE_DOC';
export const MARK_OPEN = 'MARK_OPEN';
export const GET_CREDIT_NOTE_JOURNALS = 'GET_CREDIT_NOTE_JOURNALS';
export const CLEAR_CREDIT_NOTE_JOURNALS = 'CLEAR_CREDIT_NOTE_JOURNALS';
export const CREDIT_NOTES_LOADING = 'CREDIT_NOTES_LOADING';
export const CREDIT_NOTES_LOADED = 'CREDIT_NOTES_LOADED';
export const EDIT_CREDIT_NOTE_NOTES = 'EDIT_CREDIT_NOTE_NOTES';
export const DELETE_CREDIT_NOTE_NOTE = 'DELETE_CREDIT_NOTE_NOTE';

export const getLatestInvoiceCreditNum = () => async (_, getState) => {
  const { data } = await axios.get(
    `${API_URL}/api/accounting/sales/invoiceCreditNote/latest`,
    tokenConfig(getState)
  );
  return data;
};

// GET CREDIT NOTE
export const getCreditNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/creditNotes/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CREDIT_NOTE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR CREDIT NOTE
export const clearCreditNote = () => {
  return { type: CLEAR_CREDIT_NOTE };
};

// GET CREDIT NOTES LIST
export const getCreditNotesList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: CREDIT_NOTES_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/list/creditNotes`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_CREDIT_NOTES_LIST, payload: res.data });
    dispatch({ type: CREDIT_NOTES_LOADED });
  } catch (err) {
    dispatch({ type: CREDIT_NOTES_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET CREDIT NOTES
export const getCreditNotes = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/creditNotes/`,
      { ...tokenConfig(getState), params }
    );
    dispatch({ type: GET_CREDIT_NOTES, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// DELETE CREDIT NOTE
export const deleteCreditNote = creditNote => async (dispatch, getState) => {
  const newActivityLog = {
    customer_id: creditNote.sales_account_id,
    activity_type: 'Credit Note',
    activity_title: 'Credit Note Deleted',
    module_num: creditNote.credit_note_num,
    amount: creditNote.without_change_grand_total,
    description: `Credit Note "${
      creditNote.credit_note_formatted_number
    }" of amount ${creditNote.currency_symbol}${formatAmount(
      creditNote.without_change_grand_total
    )} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/creditNotes/${creditNote.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'credit_note',
          credit_note_date: creditNote.credit_note_date,
        },
      }
    );
    dispatch(createMessage({ message: 'Credit Note Deleted' }));
    dispatch({ type: DELETE_CREDIT_NOTE, payload: creditNote.id });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD CREDIT NOTE
export const addCreditNote = values => async (dispatch, getState) => {
  const modifiedCreditNoteItems = [];
  const creditNoteItems = values.credit_note_items.map(item => ({
    service_type: item.service_type,
    description: item.description,
    currency: item.currency,
    unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
    num_units: item.num_units,
    num_nights: item.num_nights,
    amount_ex_vat: round(item.amount_ex_vat, 2),
    vat_rate: item.vat_rate,
    vat_amount: round(item.vat_amount, 2),
    gross_amount: round(item.gross_amount, 2),
    cost_price: round(item.cost_price, 2),
    cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
    cost_vat_rate: item.cost_vat_rate,
    cost_vat_amount: round(item.cost_vat_amount, 2),
    total_cost: round(item.total_cost, 2),
    profit: round(item.profit, 2),
  }));

  Object.values(groupArrayOfObjects(creditNoteItems, 'service_type')).forEach(
    item => {
      item.forEach((subItem, index) => {
        const itemCounter = index + 1;
        modifiedCreditNoteItems.push({
          ...subItem,
          service_type_name: subItem.service_type + itemCounter,
        });
      });
    }
  );
  const creditNoteNotes = [
    {
      note_type: 'create',
      notes: values.notes,
    },
  ];

  const newCreditNote = {
    invoice_id: values.invoice_id,
    sales_account_id: values.sales_account_id,
    sales_company: values.sales_company,
    customer_type: values.customer_type,
    currency: values.currency,
    sales_person_id: values.sales_person_id,
    place_of_supply: values.place_of_supply,
    exchange_rate_of_creditnote_currency: round(values.exchange_rate, 6),
    credit_note_date:
      values.credit_note_date && values.credit_note_date !== ''
        ? moment(values.credit_note_date).format('YYYY-MM-DD')
        : null,
    reference_num: values.reference_num,
    without_change_amount_total: round(values.amount_total, 2),
    without_change_vat_total: round(values.vat_total, 2),
    without_change_grand_total: round(values.grand_total, 2),
    is_tax_inclusive: true,
    customer_notes: values.customer_notes,
    terms: values.terms,
    credit_note_items: modifiedCreditNoteItems,
    credits_remaining: round(values.grand_total, 2),
    type: 'credit_note',
    other_amount: round(values.other_amount, 2),
    credit_note_records: values.notes ? creditNoteNotes : [],
    show_stamp: values.show_stamp,
    stamp: values.stamp,
    signature: values.signature,
  };

  if (values.status) {
    newCreditNote.status = values.status;
  }
  if (values.parent) newCreditNote.parent = values.parent;

  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/creditNotes/`,
      newCreditNote,
      tokenConfig(getState)
    );
    const newActivityLog = {
      module_id: res.data.id,
      customer_id: values.sales_account_id,
      activity_type: 'Credit Note',
      activity_title: 'Credit Note Added',
      module_num: values.credit_note_num,
      amount: values.grand_total,
      description: `Credit Note of amount ${
        values.currency_symbol
      }${formatAmount(values.grand_total)} created`,
    };

    dispatch(createMessage({ message: 'Credit Note Added' }));
    dispatch({ type: ADD_CREDIT_NOTE, payload: res.data });
    dispatch(addActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT CREDIT NOTE
export const editCreditNote = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestInvoiceCreditNum());
    let lastNum = latestNum.latest_num || 0;
    const modifiedCreditNoteItems = [];
    const creditNoteItems = values.credit_note_items.map(item => ({
      service_type: item.service_type,
      description: item.description,
      currency: item.currency,
      unit_price_ex_vat: round(item.unit_price_ex_vat, 2),
      num_units: item.num_units,
      num_nights: item.num_nights,
      amount_ex_vat: round(item.amount_ex_vat, 2),
      vat_rate: item.vat_rate,
      vat_amount: round(item.vat_amount, 2),
      gross_amount: round(item.gross_amount, 2),
      cost_price: round(item.cost_price, 2),
      cost_amount_ex_vat: round(item.cost_amount_ex_vat, 2),
      cost_vat_rate: item.cost_vat_rate,
      cost_vat_amount: round(item.cost_vat_amount, 2),
      total_cost: round(item.total_cost, 2),
      profit: round(item.profit, 2),
    }));

    Object.values(groupArrayOfObjects(creditNoteItems, 'service_type')).forEach(
      item => {
        item.forEach((subItem, index) => {
          const itemCounter = index + 1;
          modifiedCreditNoteItems.push({
            ...subItem,
            service_type_name: subItem.service_type + itemCounter,
          });
        });
      }
    );

    const invoiceCreditNotes = values.invoice_credit_notes.map(item => ({
      ...item,
      transaction_num: ++lastNum,
    }));

    const creditNoteNotes = [
      {
        note_type: 'update',
        notes: values.notes,
      },
    ];

    const editedCreditNote = {
      invoice_id: values.invoice_id,
      status: values.status,
      sales_account_id: values.sales_account_id,
      sales_company: values.sales_company,
      customer_type: values.customer_type,
      currency: values.currency,
      sales_person_id: values.sales_person_id,
      place_of_supply: values.place_of_supply,
      exchange_rate_of_creditnote_currency: round(values.exchange_rate, 6),
      credit_note_date:
        values.credit_note_date && values.credit_note_date !== ''
          ? moment(values.credit_note_date).format('YYYY-MM-DD')
          : null,
      reference_num: values.reference_num,
      without_change_amount_total: round(values.amount_total, 2),
      without_change_vat_total: round(values.vat_total, 2),
      without_change_grand_total: round(values.grand_total, 2),
      is_tax_inclusive: true,
      customer_notes: values.customer_notes,
      terms: values.terms,
      credit_note_items: modifiedCreditNoteItems,
      invoice_credit_notes: invoiceCreditNotes,
      type: 'credit_note',
      other_amount: round(values.other_amount, 2),
      credit_note_records: values.notes ? creditNoteNotes : [],
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
      exchange_rate: 1,
      grand_total: 1,
    };

    const { data } = await axios.put(
      `${API_URL}/api/accounting/sales/creditNotes/${values.id}/`,
      editedCreditNote,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: data.id,
      customer_id: data.sales_account_id,
      activity_type: 'Credit Note',
      activity_title: 'Credit Note Updated',
      module_num: data.credit_note_num,
      amount: data.without_change_grand_total,
      description: `Credit Note "${data.credit_note_formatted_number}" updated`,
    };

    await dispatch(addActivityLog(newActivityLog));
    await dispatch(getCreditNote(values.id));

    dispatch(createMessage({ message: 'Credit Note Updated' }));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET LATEST CREDIT NOTE
export const getLatestCreditNote = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/creditNotes/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET CREDIT NOTE DOCS
export const getCreditNoteDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/creditNotes/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_CREDIT_NOTE_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD CREDIT NOTES FILE
export const uploadCreditNotesFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/sales/creditNotes/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_CREDIT_NOTE_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE CREDIT NOTE DOC
export const deleteCreditNoteDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/creditNotes/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Credit Note Doc Deleted' }));
    dispatch({ type: DELETE_CREDIT_NOTE_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// MARK CREDIT NOTE AS OPEN
export const markCreditNoteAsOpen =
  creditNote => async (dispatch, getState) => {
    const newActivityLog = {
      module_id: creditNote.id,
      customer_id: creditNote.sales_account_id,
      activity_type: 'Credit Note',
      activity_title: 'Credit Note Updated',
      module_num: creditNote.invoice_num,
      amount: round(creditNote.without_change_grand_total, 2),
      description: `Credit Note "${creditNote.credit_note_formatted_number}" status changed from draft to open `,
    };

    try {
      const res = await axios.get(
        `${API_URL}/api/accounting/sales/creditNotes/${creditNote.id}/open`,
        tokenConfig(getState)
      );
      dispatch({
        type: MARK_OPEN,
        payload: res.data,
      });
      await dispatch(addActivityLog(newActivityLog));
    } catch (err) {
      if (err.response)
        dispatch(returnErrors(err.response.data, err.response.status));
      dispatch(returnErrors('An unexpected error occured', 500));
    }
  };

// REFUND CREDIT NOTE
export const refundCreditNote = values => async (dispatch, getState) => {
  const latestNum = await dispatch(getLatestInvoiceCreditNum());
  const lastNum = latestNum.latest_num || 0;
  const refundData = {
    credit_note_id: values.credit_note_id,
    invoice_credit_notes: [
      {
        transaction_num: lastNum + 1,
        payment_mode: values.payment_mode,
        amount_applied: values.amount_applied,
        from_account_id: values.from_account_id,
        reference_num: values.reference_num,
        refunded_on: values.refunded_on
          ? moment(values.refunded_on).format('YYYY-MM-DD')
          : '',
        description: values.description,
      },
    ],
  };

  const newActivityLog = {
    customer_id: values.sales_account_id,
    activity_type: 'Credit Note',
    activity_title: 'Credit Note Refunded',
    module_num: values.credit_note_num,
    amount: values.amount_applied,
    description: `Refund added for the Credit Note "${values.credit_note_num_with_suffix}"`,
  };

  try {
    await axios.post(
      `${API_URL}/api/accounting/sales/creditnote/amountapply`,
      refundData,
      tokenConfig(getState)
    );
    dispatch(getCreditNote(values.credit_note_id));
    dispatch(addActivityLog(newActivityLog));
    dispatch(createMessage({ message: 'Credits Refunded Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};

// APPLY CREDIT NOTE
export const applyToInvoice = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestInvoiceCreditNum());
    let lastNum = latestNum.latest_num || 0;

    const invoiceCreditNotes = values.unpaid_invoices
      .filter(item => item.amount_applied > 0)
      .map(invoice => {
        if (invoice.invoice_num === 'Account Opening Balance') {
          return {
            transaction_num: ++lastNum,
            sales_account_id: invoice.id,
            amount_applied: invoice.amount_applied,
          };
        }
        return {
          transaction_num: ++lastNum,
          invoice_id: invoice.id,
          amount_applied: invoice.amount_applied,
        };
      });

    let invoiceNums = '';

    invoiceNums = values.unpaid_invoices
      .filter(invoice => invoice.amount_applied > 0)
      .reduce(
        (acc, val) =>
          val.invoice_prefix
            ? `${acc}${val.invoice_num}-${val.customer_type_suffix}-${val.invoice_prefix}, `
            : `${acc}${val.invoice_num}, `,
        ''
      );

    const newActivityLog = {
      module_id: values.id,
      customer_id: values.sales_account_id,
      activity_type: 'Credit Note',
      activity_title: 'Credit Note Updated',
      module_num: values.credit_note_num,
      amount: values.grand_total,
      description: `Credit Note "${values.credit_note_num_with_suffix}" applied to ${invoiceNums}`,
    };

    const data = {
      credit_note_id: values.id,
      invoice_credit_notes: invoiceCreditNotes,
    };

    await axios.post(
      `${API_URL}/api/accounting/sales/creditnote/amountapply`,
      data,
      tokenConfig(getState)
    );

    dispatch(getCreditNote(values.id));
    dispatch(addActivityLog(newActivityLog));
    dispatch(createMessage({ message: 'Credits Applied Successfully' }));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};

// GET CREDIT NOTE JOURNALS
export const getCreditNoteJournals = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/credit/note/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_CREDIT_NOTE_JOURNALS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR CREDIT NOTE JOURNALS
export const clearCreditNoteJournals = () => ({
  type: CLEAR_CREDIT_NOTE_JOURNALS,
});

// EDIT CREDIT NOTE NOTES
export const editCreditNoteNote = values => async (dispatch, getState) => {
  const invoiceNote = {
    credit_note_id: values.credit_note_id,
    note_type: 'update',
    notes: values.notes,
  };
  try {
    const res = await axios.put(
      `${API_URL}/api/accounting/sales/creditNotes/records/${values.id}/`,
      invoiceNote,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Credit Notes Note Updated' }));
    dispatch({ type: EDIT_CREDIT_NOTE_NOTES, payload: res.data });
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET CREDIT NOTE NOTES
export const getCreditNoteNote = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/creditNotes/records/${id}/`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
  return null;
};

// DELETE CREDIT NOTE NOTES
export const deleteCreditNoteNote = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/sales/creditNotes/records/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Credit Notes Note Deleted' }));
    dispatch({ type: DELETE_CREDIT_NOTE_NOTE, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
