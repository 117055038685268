/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */

import axios from 'axios';
import moment from 'moment';
import { createMessage, returnErrors } from './messagesActions';
import { API_URL } from '../../utils/constants';
import {
  calculatePurchaseByItemComparisonTotal,
  formatPurchaseByItemComparisonReportValues,
  reduceExpenseByCategoryDuplicateItemValues,
  groupPurchaseByItemReport,
  groupPurchaseByItemSimpleData,
} from '../../utils/responseDataFormatters';
import {
  DEFAULT_CLASSFICATION_BASIS,
  DEFAULT_CLASS_A_VALUE,
  DEFAULT_CLASS_B_VALUE,
  DEFAULT_CLASS_C_VALUE,
} from '../../containers/Reports/custom-hooks/useABCClassificationCustomFilter';
import { formatReportComparisonFilters } from '../../utils/helpers';
import { tokenConfig } from './authActions';

export const CLEAR_REPORTS = 'CLEAR_REPORTS';
export const GET_REPORTS = 'GET_REPORTS';
export const SET_REPORTS_FILTER = 'SET_REPORTS_FILTER';
export const GET_VAT_AUDIT_DATA = 'GET_VAT_AUDIT_DATA';
export const GENERATE_VAT_AUDIT = 'GENERATE_VAT_AUDIT';
export const REPORT_LOADING = 'REPORT_LOADING';
export const REPORT_LOADED = 'REPORT_LOADED';
export const RECORD_EXIST = 'RECORD_EXIST';
export const CLEAR_CHECK_RECORD = 'CLEAR_CHECK_RECORD';

export const clearReportsData = () => ({ type: CLEAR_REPORTS });

export const setReportsFilter = filters => ({
  type: SET_REPORTS_FILTER,
  payload: filters,
});

export const setReportsData = data => ({
  type: GET_REPORTS,
  payload: data,
});

export const getProfitLossReports = filters => async (dispatch, getState) => {
  let relativeUrl = '/api/report/profit/loss';

  const queryParams = {
    duration: filters.duration,
    ...formatReportComparisonFilters(filters),
  };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }

  if (filters.type) queryParams.type = filters.type;
  if (filters.filter_accounts) queryParams.non_zero = filters.filter_accounts;
  if (filters.number_of_year)
    queryParams.number_of_year = filters.number_of_year;

  // For Comparision filter to get details on MONTHLY and YEARLY basis.
  if (filters.selected_duration) {
    queryParams.duration = 'custom';
    const isYearOnly = dateString => moment(dateString, 'YYYY', true).isValid();
    if (isYearOnly(filters.selected_duration)) {
      const startYear = moment(filters.selected_duration).year();
      const endYear = moment(filters.selected_duration).year();
      queryParams.custom_start_date = `${startYear}-01-01`;
      queryParams.custom_end_date = `${endYear}-12-31`;
    }
    if (!isYearOnly(filters.selected_duration)) {
      queryParams.custom_start_date = moment(filters.selected_duration)
        .startOf('month')
        .format('YYYY-MM-DD');
      queryParams.custom_end_date = moment(filters.selected_duration)
        .endOf('month')
        .format('YYYY-MM-DD');
    }
  }
  // END HERE
  if (filters.comparison === 'monthly' && !filters.selected_duration) {
    relativeUrl = '/api/profit/loss/comparison/report';
  } else if (filters.comparison === 'yearly' && !filters.selected_duration) {
    relativeUrl = '/api/profit/loss/yearly/comparison/report';
    // delete queryParams.duration;
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}${relativeUrl}`, {
      ...tokenConfig(getState),
      params: queryParams,
    });

    // let payload = res.data;

    // if (filters.comparison) {
    //   const headings = [...res.data.heading] || [];
    //   const { data } = res.data;
    //   headings.push({ key: 'total', title: 'Total' });
    //   const expense = data.expense.map(item => {
    //     const total = res.data.heading
    //       .slice(1)
    //       .reduce((acc, val) => acc + item[val.key], 0);
    //     return {
    //       ...item,
    //       total,
    //     };
    //   });
    //   const otherIncome = data.other_income.map(item => {
    //     const total = res.data.heading
    //       .slice(1)
    //       .reduce((acc, val) => acc + item[val.key], 0);
    //     return {
    //       ...item,
    //       total,
    //     };
    //   });

    //   const reportData = {
    //     expense,
    //     other_income: otherIncome,
    //   };

    //   if (data.Charges_Other_) {
    //     reportData.Charges_Other_ = {
    //       ...data.Charges_Other_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + data.Charges_Other_[val.key], 0),
    //     };
    //   }

    //   if (data.Income_General_) {
    //     reportData.Income_General_ = {
    //       ...data.Income_General_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + data.Income_General_[val.key], 0),
    //     };
    //   }

    //   if (data.Income_Interest_) {
    //     reportData.Income_Interest_ = {
    //       ...data.Income_Interest_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + data.Income_Interest_[val.key], 0),
    //     };
    //   }

    //   if (data.Loss_or_Gain_Exchange_) {
    //     reportData.Loss_or_Gain_Exchange_ = {
    //       ...data.Loss_or_Gain_Exchange_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce(
    //           (acc, val) => acc + data.Loss_or_Gain_Exchange_[val.key],
    //           0
    //         ),
    //     };
    //   }

    //   if (data.Sales_) {
    //     reportData.Sales_ = {
    //       ...data.Sales_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + data.Sales_[val.key], 0),
    //     };
    //   }

    //   if (data.Sales_of_Cost_) {
    //     reportData.Sales_of_Cost_ = {
    //       ...data.Sales_of_Cost_,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + data.Sales_of_Cost_[val.key], 0),
    //     };
    //   }

    //   payload = {
    //     heading: headings,
    //     data: reportData,
    //     start_date: res.data.start_date,
    //     end_date: res.data.end_date,
    //   };
    // }

    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getCashFlowReports = filters => async (dispatch, getState) => {
  let relativeUrl = '/api/cash/flow/report';
  const queryParams = {
    duration: filters.duration,
    ...formatReportComparisonFilters(filters),
  };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.type) queryParams.type = filters.type;
  if (filters.filter_accounts) queryParams.non_zero = filters.filter_accounts;
  if (filters.number_of_year)
    queryParams.number_of_year = filters.number_of_year;

  if (filters.comparison === 'monthly') {
    relativeUrl = '/api/cash/flow/comparison/report';
  } else if (filters.comparison === 'yearly') {
    relativeUrl = '/api/cash/flow/yearly/comparison/report';
    // delete queryParams.duration;
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}${relativeUrl}`, {
      ...tokenConfig(getState),
      params: queryParams,
    });

    // let payload = res.data;

    // if (filters.comparison) {
    //   const headings = [...res.data.heading] || [];
    //   const { data } = res.data;
    //   headings.push({ key: 'total', title: 'Total' });
    //   const reportData = data.map(item => {
    //     const total = res.data.heading
    //       .slice(1)
    //       .reduce((acc, val) => acc + item[val.key], 0);
    //     return {
    //       ...item,
    //       total,
    //     };
    //   });

    //   payload = {
    //     heading: headings,
    //     data: reportData,
    //     start_date: res.data.start_date,
    //     end_date: res.data.end_date,
    //   };

    //   if (res.data.net_income) {
    //     payload.net_income = {
    //       ...res.data.net_income,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + res.data.net_income[val.key], 0),
    //     };
    //   }

    //   if (res.data.beginning_cash_balance) {
    //     payload.beginning_cash_balance = {
    //       ...res.data.beginning_cash_balance,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce(
    //           (acc, val) => acc + res.data.beginning_cash_balance[val.key],
    //           0
    //         ),
    //     };
    //   }

    //   if (res.data.payable) {
    //     payload.payable = {
    //       ...res.data.payable,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + res.data.payable[val.key], 0),
    //     };
    //   }

    //   if (res.data.receivable) {
    //     payload.receivable = {
    //       ...res.data.receivable,
    //       total: res.data.heading
    //         .slice(1)
    //         .reduce((acc, val) => acc + res.data.receivable[val.key], 0),
    //     };
    //   }
    // }

    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};
export const getBusinessPerformanceRatioReport =
  filters => async (dispatch, getState) => {
    const queryParams = { ...filters };

    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    // const isCompared = Boolean(filters.comparison);

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/business/performance/ratio/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({
        type: GET_REPORTS,
        payload: res.data,
      });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getMovementOfEquityReports =
  filters => async (dispatch, getState) => {
    let relativeUrl = '/api/equity/movement/report';
    const queryParams = {
      duration: filters.duration,
      ...formatReportComparisonFilters(filters),
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.type) queryParams.type = filters.type;
    if (filters.filter_accounts) queryParams.non_zero = filters.filter_accounts;

    if (filters.comparison === 'monthly') {
      relativeUrl = '/api/equity/movement/monthly/comparison/report';
    } else if (filters.comparison === 'yearly') {
      queryParams.number_of_year = filters.number_of_periods;
      relativeUrl = '/api/equity/movement/yearly/comparison/report';
      // delete queryParams.duration;
    }
    const isCompared = Boolean(filters.comparison);
    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}${relativeUrl}`, {
        ...tokenConfig(getState),
        params: queryParams,
      });

      dispatch({
        type: GET_REPORTS,
        payload: { ...res.data, is_compared: isCompared },
      });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getBalanceSheetReports = filters => async (dispatch, getState) => {
  let relativeUrl = '/api/report/balance/sheet';
  const queryParams = {
    duration: filters.duration,
    ...formatReportComparisonFilters(filters),
  };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.type) queryParams.type = filters.type;
  if (filters.filter_accounts) queryParams.non_zero = filters.filter_accounts;
  if (filters.number_of_year)
    queryParams.number_of_year = filters.number_of_year;

  if (filters.comparison === 'monthly') {
    relativeUrl = '/api/balance/sheet/comparison/report';
  } else if (filters.comparison === 'yearly') {
    relativeUrl = '/api/balance/sheet/yearly/comparison/report';
    // delete queryParams.duration;
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}${relativeUrl}`, {
      ...tokenConfig(getState),
      params: queryParams,
    });

    // let payload = res.data;

    // if (filters.comparison) {
    //   const headings = [...res.data.heading] || [];
    //   const { data } = res.data;
    //   headings.push({ key: 'total', title: 'Total' });
    //   const reportData = data.map(item => {
    //     const total = res.data.heading
    //       .slice(1)
    //       .reduce((acc, val) => acc + item[val.key], 0);
    //     return {
    //       ...item,
    //       total,
    //     };
    //   });

    //   payload = {
    //     heading: headings,
    //     data: reportData,
    //     start_date: res.data.start_date,
    //     end_date: res.data.end_date,
    //   };
    // }

    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getSalesByCustomerReports =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/sales/by/customer/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSalesByCustomerDetail =
  (customerId, filters) => async (dispatch, getState) => {
    const queryParams = {
      customer_id: customerId,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/sales/by/customer/detail/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSalesByItemReports = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.item_name) queryParams.item_name = filters.item_name;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/sales/by/item/report`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getSalesByItemDetail =
  (itemName, filters) => async (dispatch, getState) => {
    const queryParams = {
      item_name: itemName,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/sales/by/item/detail/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSalesBySalesPersonReports =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.sales_person) queryParams.sales_person = filters.sales_person;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/sales/by/sales/person/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSalesBySalesPersonDetail =
  (salesPersonId, filters) => async (dispatch, getState) => {
    const queryParams = {
      sales_person_id: salesPersonId,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/sales/by/sales/person/detail/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSalesBySaleTypeReports =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;
    if (filters.customer_type) queryParams.type = filters.customer_type;
    else queryParams.type = 'vip';

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/sales/by/type/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPaymentReceivedReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/payment/received/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getCreditNoteDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payment/received/creditNote/detail/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getRefundHistoryReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.customer_id) queryParams.customer_id = filters.customer_id;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/payment/received/refund/history/report`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getAccountTransactionDetail =
  (accountId, filters) => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    // only for comparison filter
    if (filters.comparison) {
      if (filters.comparison === 'monthly') {
        queryParams.duration = 'custom';
        queryParams.custom_start_date = moment(filters.selected_duration)
          .startOf('month')
          .format('YYYY-MM-DD');
        queryParams.custom_end_date = moment(filters.selected_duration)
          .endOf('month')
          .format('YYYY-MM-DD');
      }
      if (filters.comparison === 'yearly') {
        queryParams.duration = 'custom';
        const startYear = moment(filters.selected_duration).year();
        const endYear = moment(filters.selected_duration).year();
        queryParams.custom_start_date = `${startYear}-01-01`;
        queryParams.custom_end_date = `${endYear}-12-31`;
      }
    }
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.type) queryParams.type = filters.type;
    if (filters.transaction_type)
      queryParams.transaction_type = filters.transaction_type;
    if (filters.transaction_num)
      queryParams.transaction_num = filters.transaction_num;
    if (filters.report_name) queryParams.report_name = filters.report_name;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/accountant/chart/of/account/report/${accountId}/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchasesBySupplierReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/purchase/by/vendor/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchasesByPurchaseTypeReports =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }
    if (filters.customer_type) queryParams.type = filters.customer_type;
    else queryParams.type = 'vip';

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/purchase/by/type/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchasesByItemReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration,
      coa_name: filters.coa_name || undefined,
      item_name: filters.item_name || undefined,
      ...formatReportComparisonFilters(filters),
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    const isGrouped = Boolean(filters.group_by);

    try {
      dispatch({ type: REPORT_LOADING });
      if (filters.comparison) {
        axios
          .get(`${API_URL}/api/purchase/by/item/comparison/report`, {
            ...tokenConfig(getState),
            params: queryParams,
          })
          .then(res => {
            const formattedItems = formatPurchaseByItemComparisonReportValues(
              res.data.data
            );
            const comparisonTotals = calculatePurchaseByItemComparisonTotal(
              res.data.data
            );
            const finalReportData = isGrouped
              ? groupPurchaseByItemReport(formattedItems)
              : [...formattedItems];

            dispatch({
              type: GET_REPORTS,
              payload: {
                ...res.data,
                is_grouped: isGrouped,
                data: finalReportData,
                totals: comparisonTotals,
              },
            });
          });
      } else {
        const res = await axios.get(`${API_URL}/api/purchase/by/item/report`, {
          ...tokenConfig(getState),
          params: queryParams,
        });
        const finalReportData = isGrouped
          ? groupPurchaseByItemSimpleData(res.data.data)
          : [...res.data.data];

        dispatch({
          type: GET_REPORTS,
          payload: {
            ...res.data,
            is_grouped: isGrouped,
            data: finalReportData,
          },
        });
      }
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getExpenseDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration,
      group_by: filters.group_by,
      entities: filters.entities,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/expense/detail/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getExpensesByCategoryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration,
      filter_by: filters.filter_by,
      expense_status: filters.expense_status,
      bill_status: filters.bill_status,
      account_type: filters.account_type,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/expense/by/category/report`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      const modifiedData = reduceExpenseByCategoryDuplicateItemValues(
        res.data.data
      );
      dispatch({
        type: GET_REPORTS,
        payload: {
          ...res.data,
          data: modifiedData,
        },
      });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const clearCheckRecord = () => {
  return {
    type: CLEAR_CHECK_RECORD,
  };
};

export const checkRecordExist = (type, id) => async (dispatch, getState) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/api/accounting/sales/customer/ActivityLog/activity_view_detail/?activity_type=${type}&module_num=${id}`,
      tokenConfig(getState)
    );
    dispatch({ type: RECORD_EXIST, payload: data.Exist });
    if (!data.Exist)
      dispatch(returnErrors({ message: ['No Record Found!'] }, 404));
  } catch (err) {
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getActivityLogReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/activity/log/report`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getExceptionReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/activity/log/exception/report`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getPurchaseBySupplierDetails =
  filters => async (dispatch, getState) => {
    const queryParams = {
      supplier_id: filters.supplier_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/purchase/by/vendor/report/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchaseByItemDetails =
  filters => async (dispatch, getState) => {
    const queryParams = {
      item_name: filters.item_name,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/purchase/by/item/report/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getExpensesByCategoryDetails =
  filters => async (dispatch, getState) => {
    const queryParams = {
      expense_account_name: filters.category_name,
      duration: filters.duration || 'this month',
      filter_by: filters.filter_by,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/expense/by/category/report/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

// Receivable Reports

export const getCustomerBalancesReports =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/customer/balance`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getCustomerBalancesDetails =
  filters => async (dispatch, getState) => {
    const queryParams = {
      customer_id: filters.customer_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/customer/balance/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getCustomerBalanceCreditDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      customer_id: filters.customer_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/customer/balance/credit/note/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getCustomerBalanceInvoiceDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      customer_id: filters.customer_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/customer/balance/invoice/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getArAgingSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const { data: response } = await axios.get(
        `${API_URL}/api/report/ar/aging/summary`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      const payload = {
        start_date: response.start_date,
        end_date: response.end_date,
        data: response.data.map(item => {
          const agingData = {
            id: item.sales_account__id,
            customer: item.account_name,
            currency_symbol: item.sales_account__currency_symbol,
            current: item.current_bcy || 0,
            date_1_15: item.days_1_15 || 0,
            date_16_30: item.days_16_30 || 0,
            date_31_45: item.days_31_45 || 0,
            date_gt_45: item.days_above_45 || 0,
            total: item.total_bcy,
            total_fcy: item.total_fcy,
          };

          return agingData;
        }),
      };

      dispatch({ type: GET_REPORTS, payload });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getArAgingSummaryDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      date_type: filters.date_type || 'due_date',
      customer_id: filters.customer_id,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    if (filters.interval) queryParams.interval = filters.interval;
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/ar/aging/summary/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getArAgingDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      date_type: filters.date_type || 'due_date',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;
    if (filters.interval) queryParams.interval = filters.interval;

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/ar/aging/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(clearReportsData());
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getInvoiceDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/invoice/details`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getReceivableDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/receive/receiveable/details`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getReceivableSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.customer_id) queryParams.customer_id = filters.customer_id;
    if (filters.status) queryParams.status = filters.status;

    try {
      dispatch({ type: REPORT_LOADING });
      const { data: res } = await axios.get(
        `${API_URL}/api/report/receive/receiveable/summary`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      const payload = {
        start_date: res.start_date,
        end_date: res.end_date,
        data: res.data.map(item => ({
          ...item,
          amount: item.invoice_amount || item.credit_amount || item.amount || 0,
          exchange_rate: item.exchange_rate || 1,
        })),
      };

      dispatch({ type: GET_REPORTS, payload });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

// Payables Reports

export const getBillDetailsReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.supplier_id) {
    queryParams.supplier_id = filters.supplier_id;
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/payables/report/bill/detail`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getSupplierCreditDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payables/report/supplierCredit/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPaymentsMadeReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.supplier_id) {
    queryParams.supplier_id = filters.supplier_id;
  }

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/payables/report/payment/made`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getSupplierRefundHistoryReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) queryParams.supplier_id = filters.supplier_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payables/report/refund/history`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchaseOrderDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payables/report/pur/order/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchaseOrdersbySupplierReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payables/report/pur/order/by/vendor`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPurchaseOrdersbySupplierDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      supplier_id: filters.supplier_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/payables/report/pur/order/by/vendor/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPayableSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) queryParams.supplier_id = filters.supplier_id;
    if (filters.status) queryParams.status = filters.status;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/payables/report/summary`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getPayableDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/payables/report/detail`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSupplierBalancesReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/vendor/payable/balance`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSupplierBalancesDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      supplier_id: filters.supplier_id,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/payable/vendor/balance/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSupplierBalanceExcessPaymentDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      supplier_id: filters.supplier_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/payable/vendor/balance/excess/payment/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getSupplierBalanceBillDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      supplier_id: filters.supplier_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/payable/vendor/balance/bill/detail`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getApAgingSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) queryParams.supplier_id = filters.supplier_id;

    try {
      dispatch({ type: REPORT_LOADING });
      const { data: response } = await axios.get(
        `${API_URL}/api/report/ap/aging/summary`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      const payload = {
        start_date: response.start_date,
        end_date: response.end_date,
        data: response.data.map(item => {
          const agingData = {
            id: item.supplier__id,
            supplier: item.account_name,
            currency_symbol: item.supplier__currency_symbol,
            current: item.current_bcy || 0,
            date_1_15: item.days_1_15 || 0,
            date_16_30: item.days_16_30 || 0,
            date_31_45: item.days_31_45 || 0,
            date_gt_45: item.days_above_45 || 0,
            total: item.total_bcy,
            total_fcy: item.total_fcy,
          };

          return agingData;
        }),
      };

      dispatch({ type: GET_REPORTS, payload });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getApAgingSummaryDetail =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      date_type: filters.date_type || 'due_date',
      supplier_id: filters.supplier_id,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    if (filters.interval) queryParams.interval = filters.interval;

    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/ap/aging/summary/details`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getApAgingDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      date_type: filters.date_type || 'due_date',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.supplier_id) {
      queryParams.supplier_id = filters.supplier_id;
    }
    if (filters.interval) {
      queryParams.interval = filters.interval;
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const { data } = await axios.get(
        `${API_URL}/api/report/ap/aging/details`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );

      dispatch({ type: GET_REPORTS, payload: data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

// Accountant Reports

export const getAccountTypeSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      customer_id: filters.supplier_id,
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/accountant/account/type/summary/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getJournalReport = filters => async (dispatch, getState) => {
  const queryParams = {
    customer_id: filters.supplier_id,
    duration: filters.duration || 'this month',
  };
  if (filters.start_date)
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  if (filters.end_date)
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  if (filters.transaction_type)
    queryParams.transaction_type = filters.transaction_type;
  if (filters.transaction_num)
    queryParams.transaction_number = filters.transaction_num;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/accountant/journal/report`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getAccountTransactionsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.type) queryParams.type = filters.type;
    if (filters.supplier_id) queryParams.supplier_id = filters.supplier_id;
    if (filters.transaction_type)
      queryParams.transaction_type = filters.transaction_type;
    if (filters.transaction_num)
      queryParams.transaction_number = filters.transaction_num;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/accountant/account/transactions`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getTrialBalanceReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration || 'this month' };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.type) queryParams.type = filters.type;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accountant/trial/balance/report`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};
export const getGrossProfitReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration || 'this month' };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.type) queryParams.type = filters.type;
  if (filters.item_name) queryParams.item_name = filters.item_name;
  if (filters.supplier_name) queryParams.supplier_name = filters.supplier_name;
  if (filters.account_name) queryParams.account_name = filters.account_name;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(`${API_URL}/api/report/gross/profit`, {
      ...tokenConfig(getState),
      params: queryParams,
    });
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getGeneralLedgerReport = filters => async (dispatch, getState) => {
  const queryParams = { duration: filters.duration || 'this month' };
  if (filters.start_date) {
    queryParams.custom_start_date = moment(filters.start_date).format(
      'YYYY-MM-DD'
    );
  }
  if (filters.end_date) {
    queryParams.custom_end_date = moment(filters.end_date).format('YYYY-MM-DD');
  }
  if (filters.account_type) queryParams.account_type = filters.account_type;

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/report/accountant/general/ledger`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    const payload = {
      start_date: res.data.start_date,
      end_date: res.data.end_date,
      data: res.data.data.map(item => ({
        id: item.COA_id,
        name: item.COA_name,
        credit: item.COA_credits || 0,
        debit: item.COA_debits || 0,
        parent_account: item.COA_parent,
      })),
    };
    dispatch({ type: GET_REPORTS, payload });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getDetailedGeneralLedgerReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration || 'this month' };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.type) queryParams.type = filters.type;
    if (filters.account_number)
      queryParams.account_number = filters.account_number;

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/accountant/detailed/general/ledger`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getRealizedGainOrLossReport =
  filters => async (dispatch, getState) => {
    const queryParams = { duration: filters.duration || 'this month' };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/currency/realized/gain/or/loss/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getUnRealizedGainOrLossReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      duration: filters.duration || 'this month',
      sar_exchange_rate: filters.sar_exchange_rate,
      gbp_exchange_rate: filters.gbp_exchange_rate,
      eur_exchange_rate: filters.eur_exchange_rate,
      usd_exchange_rate: filters.usd_exchange_rate,
    };
    if (filters.start_date) {
      queryParams.custom_start_date = moment(filters.start_date).format(
        'YYYY-MM-DD'
      );
    }
    if (filters.end_date) {
      queryParams.custom_end_date = moment(filters.end_date).format(
        'YYYY-MM-DD'
      );
    }

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/currency/unrealized/gain/or/loss/report`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getVATAuditReportsData = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/vatAudit/`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_VAT_AUDIT_DATA,
      payload: res.data.result,
    });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const generateVATAuditReport =
  (startDate, endDate) => async (dispatch, getState) => {
    const data = {
      start_date: startDate,
      end_date: endDate,
    };

    try {
      const res = await axios.post(
        `${API_URL}/api/vatAudit/`,
        data,
        tokenConfig(getState)
      );
      dispatch(createMessage({ message: 'VAT Audit Generated Successfully' }));
      dispatch({
        type: GENERATE_VAT_AUDIT,
        payload: res.data.results,
      });
    } catch (err) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  };

export const getInventorySummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/report/inventory/summary/`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getCommittedStockDetailsReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/inventory/commited/stock/detail/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getInventoryValuationReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/inventory/valuation/summary/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getInventoryValuationDetailsReport =
  ({ item_name: itemName = '', ...filters }) =>
  async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/inventory/valuation/summary/detail/${itemName}/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getFIFOCostLotTrackingReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(`${API_URL}/api/report/inventory/fifo/`, {
        ...tokenConfig(getState),
        params: queryParams,
      });
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getInventoryAgingSummaryReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/inventory/aging/summary/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };

export const getProductSalesReport = filters => async (dispatch, getState) => {
  const queryParams = {
    ...filters,
    duration: filters.duration,
    ...(filters.duration === 'custom'
      ? {
          // Sending just custom start date and custom end date if custom duration is selected
          start_date: undefined,
          end_date: undefined,
          custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
          custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
        }
      : {}),
  };

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/report/inventory/product/sale/`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getStockSummaryReport = filters => async (dispatch, getState) => {
  const queryParams = {
    ...filters,
    duration: filters.duration,
    ...(filters.duration === 'custom'
      ? {
          // Sending just custom start date and custom end date if custom duration is selected
          start_date: undefined,
          end_date: undefined,
          custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
          custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
        }
      : {}),
  };

  try {
    dispatch({ type: REPORT_LOADING });
    const res = await axios.get(
      `${API_URL}/api/report/inventory/stock/summary/`,
      {
        ...tokenConfig(getState),
        params: queryParams,
      }
    );
    dispatch({ type: GET_REPORTS, payload: res.data });
    dispatch({ type: REPORT_LOADED });
  } catch (err) {
    dispatch({ type: REPORT_LOADED });
    dispatch(
      returnErrors(
        err.response && err.response.data,
        err.response && err.response.status
      )
    );
  }
};

export const getABCClassificationReport =
  filters => async (dispatch, getState) => {
    const queryParams = {
      ...filters,
      classification_basis: undefined,
      based_on: filters.classification_basis || DEFAULT_CLASSFICATION_BASIS,
      class_a: filters.class_a || DEFAULT_CLASS_A_VALUE,
      class_b: filters.class_b || DEFAULT_CLASS_B_VALUE,
      class_c: filters.class_c || DEFAULT_CLASS_C_VALUE,
      duration: filters.duration,
      ...(filters.duration === 'custom'
        ? {
            // Sending just custom start date and custom end date if custom duration is selected
            start_date: undefined,
            end_date: undefined,
            custom_start_date: moment(filters.start_date).format('YYYY-MM-DD'),
            custom_end_date: moment(filters.end_date).format('YYYY-MM-DD'),
          }
        : {}),
    };

    try {
      dispatch({ type: REPORT_LOADING });
      const res = await axios.get(
        `${API_URL}/api/report/inventory/abc/classification/`,
        {
          ...tokenConfig(getState),
          params: queryParams,
        }
      );
      dispatch({ type: GET_REPORTS, payload: res.data });
      dispatch({ type: REPORT_LOADED });
    } catch (err) {
      dispatch({ type: REPORT_LOADED });
      dispatch(
        returnErrors(
          err.response && err.response.data,
          err.response && err.response.status
        )
      );
    }
  };
