/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import axios from 'axios';
import moment from 'moment';
import round from 'lodash/round';
import { tokenConfig } from './authActions';
import { API_URL } from '../../utils/constants';
import { createMessage, returnErrors } from './messagesActions';
import { addSupplierActivityLog } from './suppliersActions';
import formatAmount from '../../utils/formatAmount';

export const GET_PAYMENTS_MADE_LIST = 'GET_PAYMENTS_MADE_LIST';
export const GET_PAYMENTS_MADE = 'GET_PAYMENTS_MADE';
export const GET_PAYMENT_MADE = 'GET_PAYMENT_MADE';
export const CLEAR_PAYMENT_MADE = 'CLEAR_PAYMENT_MADE';
export const ADD_PAYMENT_MADE = 'ADD_PAYMENT_MADE';
export const DELETE_PAYMENT_MADE = 'DELETE_PAYMENT_MADE';
export const EDIT_PAYMENT_MADE = 'EDIT_PAYMENT_MADE';
export const GET_PAYMENT_MADE_DOCS = 'GET_PAYMENT_MADE_DOCS';
export const ADD_PAYMENT_MADE_DOCS = 'ADD_PAYMENT_MADE_DOCS';
export const DELETE_PAYMENT_MADE_DOC = 'DELETE_PAYMENT_MADE_DOC';
export const GET_PAYMENT_MADE_JOURNAL = 'GET_PAYMENT_MADE_JOURNAL';
export const CLEAR_PAYMENT_MADE_JOURNAL = 'CLEAR_PAYMENT_MADE_JOURNAL';
export const PAYMENT_MADE_LOADING = 'PAYMENT_MADE_LOADING';
export const PAYMENT_MADE_LOADED = 'PAYMENT_MADE_LOADED';
export const REFUND_PAYAMENT_MADE = 'REFUND_PAYAMENT_MADE';
export const GET_PAYMENT_MADE_REFUNDS = 'GET_PAYMENT_MADE_REFUNDS';

// GET LATEST BILL PAYMENT NUMBER
export const getLatestBillPaymentNum = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/billPayment/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// GET PAYMENT MADE
export const getPaymentMade = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/paymentsMade/${id}/`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PAYMENT_MADE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// CLEAR PAYMENT MADE
export const clearPaymentMade = () => {
  return { type: CLEAR_PAYMENT_MADE };
};

// GET PAYMENTS MADE LIST
export const getPaymentsMadeList = params => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_MADE_LOADING });
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/list/paymentsMade`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENTS_MADE_LIST, payload: res.data });
    dispatch({ type: PAYMENT_MADE_LOADED });
  } catch (err) {
    dispatch({ type: PAYMENT_MADE_LOADED });
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PAYMENTS MADE
export const getPaymentsMade = params => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/paymentsMade/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENTS_MADE, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET LATEST PAYMENT MADE
export const getLatestPaymentMade = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/paymentsMade/latest`,
      tokenConfig(getState)
    );
    return res.data;
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
    return null;
  }
};

// DELETE PAYMENT MADE
export const deletePaymentMade = payment => async (dispatch, getState) => {
  const newActivityLog = {
    supplier_id: payment.supplier_id,
    activity_type: 'Payment Made',
    activity_title: 'Payment Deleted',
    module_num: payment.id,
    amount: payment.total,
    description: `Bill payment of amount ${
      payment.currency_symbol
    }${formatAmount(payment.total)} deleted`,
  };

  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/paymentsMade/${payment.id}/`,
      {
        ...tokenConfig(getState),
        data: {
          type: 'payment_made',
          payment_date: payment.payment_date,
        },
      }
    );
    dispatch(createMessage({ message: 'Payment Deleted' }));
    dispatch({ type: DELETE_PAYMENT_MADE, payload: payment.id });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// ADD PAYMENT MADE
export const addPaymentMade = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestBillPaymentNum());
    let lastNum = latestNum.latest_num ? latestNum.latest_num + 1 : 0;

    // changed 'unpaid_bills' key to 'bill' due to edit&clone functionality

    const isNoAmountApplied =
      values.unpaid_bills.filter(bill => bill.amount_applied > 0).length === 0;

    const billPayments = values.unpaid_bills
      .filter(bill => bill.amount_applied > 0)
      .map(bill => {
        if (bill.bill_num === 'Supplier Opening Balance') {
          return {
            supplier: bill.id,
            amount_applied: bill.amount_applied,
            transaction_num: ++lastNum,
          };
        }
        return {
          bill_id: bill.bill ? bill.bill.id : bill.id,
          amount_applied: bill.amount_applied,
          transaction_num: ++lastNum,
        };
      });

    const newPyamentMade = {
      supplier_id: values.supplier_id,
      chart_of_account_id: values.chart_of_account_id,
      currency: values.currency,
      // payment_num: values.payment_num,
      payment_date: moment(values.payment_date).format('YYYY-MM-DD'),
      payment_mode: values.payment_mode,
      exchange_rate: round(values.exchange_rate, 6),
      reference_num: values.reference_num,
      notes: values.notes,
      bank_charges: values.bank_charges,
      total: round(values.amount_received, 2),
      unused_amount: isNoAmountApplied
        ? round(values.amount_received, 2)
        : values.amount_in_excess,
      bill_payments: billPayments,
      type: 'payment_made',
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
    };

    if (values.parent) newPyamentMade.parent = values.parent;

    const billNums = values.unpaid_bills
      .filter(bill => bill.amount_applied > 0)
      .reduce((acc, val) => `${acc}${val.bill_num}, `, '');

    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/paymentsMade/`,
      newPyamentMade,
      tokenConfig(getState)
    );

    const newActivityLog = {
      module_id: res.data.id,
      supplier_id: values.supplier_id,
      activity_type: 'Payment Made',
      activity_title: 'Payment Added',
      module_num: values.payment_num,
      amount: round(values.amount_received, 2),
      description: `Payment of amount ${values.currency_symbol}${formatAmount(
        values.amount_received
      )} paid and applied for ${billNums}`,
    };

    dispatch(createMessage({ message: 'Payment Added' }));
    dispatch({ type: ADD_PAYMENT_MADE, payload: res.data });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// EDIT PAYMENT MADE
export const editPaymentMade = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestBillPaymentNum());
    let lastNum = latestNum.latest_num ? latestNum.latest_num + 1 : 0;

    const isNoAmountApplied =
      values.bills.filter(bill => bill.amount_applied > 0).length === 0;

    const billPayments = values.bills
      .filter(bill => bill.amount_applied > 0)
      .map(payment => {
        if (payment.supplier) {
          return {
            supplier: payment.supplier.id,
            amount_applied: payment.amount_applied,
            transaction_num: ++lastNum,
          };
        }
        if (payment.isBillPayment) {
          return {
            bill_id: payment.bill.id,
            amount_applied: payment.amount_applied,
            transaction_num: ++lastNum,
          };
        }
        return {
          bill_id: payment.id,
          amount_applied: payment.amount_applied,
          transaction_num: ++lastNum,
        };
      });

    const editedPyamentMade = {
      supplier_id: values.supplier_id,
      chart_of_account_id: values.chart_of_account_id,
      currency: values.currency,
      // payment_num: values.payment_num,
      payment_date: moment(values.payment_date).format('YYYY-MM-DD'),
      payment_mode: values.payment_mode,
      exchange_rate: round(values.exchange_rate, 6),
      reference_num: values.reference_num,
      notes: values.notes,
      bank_charges: values.bank_charges,
      total: round(values.amount_received, 2),
      unused_amount: isNoAmountApplied
        ? round(values.amount_received, 2)
        : values.amount_in_excess,
      bill_payments: billPayments,
      type: 'payment_made',
      show_stamp: values.show_stamp,
      stamp: values.stamp,
      signature: values.signature,
    };

    const newActivityLog = {
      module_id: values.id,
      supplier_id: values.supplier_id,
      activity_type: 'Payment Made',
      activity_title: 'Payment Updated',
      module_num: values.payment_num,
      amount: round(values.amount_received, 2),
      description: 'Bill payment details modified',
    };

    const res = await axios.put(
      `${API_URL}/api/accounting/purchases/paymentsMade/${values.id}/`,
      editedPyamentMade,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Updated' }));
    dispatch({ type: EDIT_PAYMENT_MADE, payload: res.data });
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    // error message dispatched from view
    throw err;
  }
};

// GET PAYMENT MADE DOCS
export const getPaymentMadeDocs = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/paymentMade/${id}/docs`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_PAYMENT_MADE_DOCS,
      payload: res.data,
    });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// UPLOAD PAYMENT MADE FILE
export const uploadPaymentMadeFile = values => async (dispatch, getState) => {
  const formData = new FormData();
  formData.append('doc_file', values.doc_file.file, values.doc_file.file.name);
  formData.append('doc_type', values.doc_file.file.type);
  formData.append('doc_name', values.doc_file.file.name);
  formData.append('doc_size_bytes', values.doc_file.file.size);

  const config = tokenConfig(getState);
  config.headers['Content-Type'] = 'multipart/form-data';
  try {
    const res = await axios.post(
      `${API_URL}/api/accounting/purchases/paymentMade/${values.id}/uploadDoc`,
      formData,
      config
    );
    dispatch(createMessage({ message: 'File Uploaded' }));
    dispatch({ type: ADD_PAYMENT_MADE_DOCS, payload: res.data });
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors('An unexpected error occured', 500));
  }
};

// DELETE PAYMENT MADE DOC
export const deletePaymentMadeDoc = id => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/paymentMade/docs/${id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Payment Made Doc Deleted' }));
    dispatch({ type: DELETE_PAYMENT_MADE_DOC, payload: id });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// GET PAYMENT MADE JOURNAL
export const getPaymentMadeJournal = id => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${API_URL}/api/accounting/sales/paymentsMade/${id}/journals`,
      tokenConfig(getState)
    );
    dispatch({ type: GET_PAYMENT_MADE_JOURNAL, payload: res.data });
  } catch (err) {
    if (err.response) {
      dispatch(returnErrors(err.response.data, err.response.status));
    }
  }
};

export const clearPaymentMadeJournal = () => ({
  type: CLEAR_PAYMENT_MADE_JOURNAL,
});

export const applyExcessPayment = values => async (dispatch, getState) => {
  try {
    const latestNum = await dispatch(getLatestBillPaymentNum());
    let lastNum = latestNum.latest_num || 0;

    let payments = [];
    if (values.type === 'Excess Payment') {
      payments = values.unpaid_bills
        .filter(item => item.amount_applied > 0)
        .map(bill => {
          if (bill.bill_num === 'Supplier Opening Balance') {
            return {
              supplier: bill.id,
              amount_applied: bill.amount_applied,
              transaction_num: ++lastNum,
              payment_made: values.payment_id,
            };
          }
          return {
            bill_id: bill.id,
            amount_applied: bill.amount_applied,
            transaction_num: ++lastNum,
            payment_made: values.payment_id,
          };
        });
    } else {
      payments = values.unpaid_bills
        .filter(item => item.amount_applied > 0)
        .map(bill => ({
          bill_id: bill.id,
          amount_applied: bill.amount_applied,
          transaction_num: ++lastNum,
          supplier: values.supplier_id,
        }));
    }

    const data = { payment_vouchers: payments };

    const billNums = values.unpaid_bills
      .filter(bill => bill.amount_applied > 0)
      .reduce((acc, val) => `${acc}${val.bill_num}, `, '');

    let newActivityLog = {
      module_id: values.id,
      supplier_id: values.supplier_id,
      activity_type: 'Payment Made',
      activity_title: 'Excess Payment Updated',
      module_num: values.payment_id,
      amount: values.grand_total,
      description: `Excess Payment of amount ${values.currency}${values.total_credit_applied} applied to bill# ${billNums}`,
    };

    if (values.type === 'Supplier Opening Balance') {
      newActivityLog = {
        module_id: values.id,
        supplier_id: values.supplier_id,
        activity_type: 'Opening Balance',
        activity_title: 'Opening Balance Updated',
        module_num: values.payment_id,
        amount: values.grand_total,
        description: `Opening Balance of amount ${values.currency}${values.total_credit_applied} applied to bill# ${billNums}`,
      };
    }

    await axios.post(
      `${API_URL}/api/accounting/purchase/paymentVoucher/amountapply`,
      data,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Credits Applied Successfully' }));
    dispatch(addSupplierActivityLog(newActivityLog));
  } catch (err) {
    if (err.response)
      dispatch(returnErrors(err.response.data, err.response.status));
    dispatch(returnErrors([{ message: 'An unexpected error occured' }], 500));
  }
};
export const getPaymentMadeRefunds = id => async (dispatch, getState) => {
  try {
    const params = {
      payment_id: id,
    };
    const res = await axios.get(
      `${API_URL}/api/accounting/purchases/payments_made/refunds/`,
      {
        ...tokenConfig(getState),
        params,
      }
    );
    dispatch({ type: GET_PAYMENT_MADE_REFUNDS, payload: res.data });
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

// REFUND EXCESS PAYMENT
export const refundExcessPayment = values => async (dispatch, getState) => {
  try {
    // const latestNum = await dispatch(getLatestBillPaymentNum());
    // let lastNum = latestNum.latest_num || 0;

    const data = {
      payment_made: values.payment_id,
      payment_mode: values.payment_mode,
      from_account_id: values.from_account_id,
      amount_applied: values.amount_applied,
      reference_num: values.reference_num,
      refunded_on: moment(values.refunded_on).format('YYYY-MM-DD'),
      description: values.description,
      // transaction_num: ++lastNum,
    };

    const res = await axios.post(
      `${API_URL}/api/purchas/payment/refund`,
      data,
      tokenConfig(getState)
    );
    dispatch({ type: REFUND_PAYAMENT_MADE, payload: res.data });
    dispatch(getPaymentMadeRefunds(values.payment_id));
    dispatch(createMessage({ message: 'Payment Refunded Successfully' }));
    dispatch(getPaymentMade(values.payment_id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};

export const editPaymentMadeRefund = values => async (dispatch, getState) => {
  try {
    const data = {
      payment_made: values.id,
      payment_mode: values.payment_mode,
      from_account_id: values.from_account_id,
      amount_applied: values.amount_applied,
      // reference_num: values.reference_num,
      refunded_on: moment(values.refunded_on).format('YYYY-MM-DD'),
      description: values.description,
    };
    await axios.put(
      `${API_URL}/api/accounting/purchases/payments_made/refunds/${values.payment_id}/`,
      data,
      tokenConfig(getState)
    );

    dispatch(createMessage({ message: 'Updated Successfully' }));
    dispatch(getPaymentMadeRefunds(values.id));
    dispatch(getPaymentMade(values.id));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
export const deletePaymentMadeRefund = values => async (dispatch, getState) => {
  try {
    await axios.delete(
      `${API_URL}/api/accounting/purchases/payments_made/refunds/${values.id}/`,
      tokenConfig(getState)
    );
    dispatch(createMessage({ message: 'Deleted Successfully' }));
    dispatch(getPaymentMadeRefunds(values.payment_made));
    dispatch(getPaymentMade(values.payment_made));
  } catch (err) {
    dispatch(returnErrors(err.response.data, err.response.status));
  }
};
